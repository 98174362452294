<template>
  <transition name="fade">
      <div v-if="ready && OwnerContracts.length && OwnerContracts" class="VehicleContractsWidget__Component">
          <div class="VehicleContractsWidget__Component__Item"
              v-for="item in OwnerContracts" :key="item.Id">
              <label>
                  בעלים:
                  <span>{{item.ContractOwner.Name}}</span>
              </label>
              <label>
                  שוכר:
                  <a :href="`/clients/${item.Profile.Id}`"><span>{{item.Profile.Name}}</span></a>
              </label>
              <label>
                  ח.פ:
                  <span>{{item.Profile.IdNumber}}</span>
              </label>
              <label>
                  מתאריך:
                  <FieldDateHourInline :value="item.StartDate" />
              </label>
              <label>
                  עד תאריך:
                  <FieldDateHourInline :value="item.EndDate" />
              </label>
              <label>
                  תאריך עדכון:
                  <FieldDateHourInline :value="item.UpdateDate" />
              </label>
              <router-link :to="{ name: 'VehicleContract', params: { id: item.Id }}">
                  <span class="preview">הצג</span>
              </router-link>
            </div>
        </div>
        <div v-else>לא קיים חוזי בעלות</div>
  </transition>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import FieldDateHourInline from '../Fields/FieldDateHourInline.vue';

export default {
  name: 'VehicleOwnershipContractsWidget',
  props: ['ready', 'namespace'],
  beforeCreate() {
      const { namespace } = this.$options.propsData;
      const { mapState } = createNamespacedHelpers(namespace);
      this.$options.computed = {
          ...mapState(['OwnerContracts']),
      };
  },
  methods: {
      init() {
          this.$emit('onReady', { titleCount: this.OwnerContracts.length });
      },
  },
  watch: {
      ready(newValue) {
          if (newValue) {
              this.init();
          }
      },
      OwnerContracts(newValue) {
          this.$emit('onReady', { titleCount: newValue?.length });
      },
  },
  components: {
      FieldDateHourInline,
  },
};
</script>

<style lang="scss" scoped>
  .VehicleContractsWidget__Component {
      // background-color: #eaf0f6;
      width: 100%;

      .VehicleContractsWidget__Component__Item {
          border: 1px solid #e4eaf1;
          background-color: #eef1f3;
          border-radius: 3px;
          margin: 10px 0;
          padding: 10px;
          position: relative;
          display: flex;
          flex-direction: column;
          font-weight: bold;

          span {
              font-weight: normal;
          }
          a {
              color: #06c;
              cursor: pointer;
              &:hover {
                  text-decoration: underline;
              }
          }
      }
      .preview {
          position: absolute;
          left: 10px;
          top: 10px;
          color: #57B7CA;
          cursor: pointer;
          font-weight: 500 !important;

          &:hover {
              text-decoration: underline;
          }
      }
  }
</style>
