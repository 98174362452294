var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "fade" } }, [
    _vm.ready && _vm.Ownership
      ? _c("div", { staticClass: "VehicleOwnersWidget__Component" }, [
          _c("div", { staticClass: "VehicleOwnersWidget__Component__Item" }, [
            _c("label", [
              _vm._v(" בעלים: "),
              _c("span", [_vm._v(_vm._s(_vm.Ownership.Name))])
            ]),
            _c(
              "label",
              [
                _vm._v(" מתאריך: "),
                _c("FieldDateHourInline", {
                  attrs: { value: _vm.Ownership.StartDate }
                })
              ],
              1
            ),
            _c(
              "label",
              [
                _vm._v(" עד תאריך: "),
                _c("FieldDateHourInline", {
                  attrs: { value: _vm.Ownership.EndDate }
                })
              ],
              1
            )
          ])
        ])
      : _c("div", [_vm._v(" לא קיימת בעלות נוכחית")])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }