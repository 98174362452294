<template>
  <transition name="fade">
    <div class="CustomerDocumentsWidget__Component" v-if="!isInitiating">
      <div v-if="ready">
        <button v-if="show" :loading="isLoading" @click.stop="preview()">
          הצג מסמך
        </button>
        <div v-if="!show && !isLoading">מסמך חסר</div>
      </div>
      <b-loading
        :is-full-page="false"
        v-model="isLoading"
        :can-cancel="false"
      ></b-loading>
    </div>
  </transition>
</template>

<script>
import DriverService from "@/services/DriverService";
import { mapGetters, createNamespacedHelpers } from "vuex";
import VueIframeWritable from "@/components/VueIframeWritable.vue";
import FilesService from "@/services/FilesService";
import { saveAs } from "file-saver";
import { ToastProgrammatic as Toast } from "buefy";
import printer from "../../plugins/printer";

export default {
  name: "PreviewDocumentWidget",
  props: ["ready", "namespace", "query", "widget"],
  beforeCreate() {
    const { namespace } = this.$options.propsData;
    const { mapState } = createNamespacedHelpers(namespace);
    this.$options.computed = {
      ...mapState(["Id", "Owner", "DriverTransferStatusEvents"]),
      ...mapGetters("transferDriver", ["form"]),
      show() {
        return this.hasDocument && !this.isInitiating && !this.isLoading;
      },
      hasDocument() {
        return this.DriverTransferStatusEvents?.some(
          (status) => status.TransferStatus.Id === 1
        );
      },
    };
  },
  data() {
    return {
      isInitiating: true,
      isLoading: false,
    };
  },
  methods: {
    async init() {
      this.$emit("onReady");
      this.isInitiating = false;
    },
    preview() {
      const loadingComponent = this.$buefy.loading.open();
      DriverService.printTransfers([this.Id])
        .then((r) => {
          this.$iframe.open({
            html: r.data?.Html,
            component: VueIframeWritable,
            onSave: async ({ close, loading }) => {
              loading(true);
              this.downloadFile(this.Id).finally(() => {
                loading(false);
                close();
              });
            },
            onPrint: async ({ loading }) => {
              loading(true);
              printer.printHtml(r.data.Html);
              loading(false);
            },
          });
        })
        .finally(() => {
          loadingComponent.close();
        });
    },

    downloadFile(url) {
      this.isLoading = true;
      return FilesService.getTransferPdf(url, {
        headers: { "Profile-Id": this.Owner.Id },
      })
        .then((r) => {
          if (r.data) {
            const blob = new Blob([r.data], { type: "application/pdf" });
            saveAs(blob, "הסבה.pdf");
          }
        })
        .catch(() => {
          Toast.open({
            type: "is-danger",
            message: "המערכת נתקלה בבעיה! יש לנסות שוב או לפנות לתמיכה הטכנית.",
            position: "is-bottom",
            duration: 8000,
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    // saveDocument(data) {
    //   this.isLoading = true;
    //   return DriverService.putDocument(this.Driver.Id, data.type, data.html)
    //     .then(() => {
    //       this.html = data.html;
    //       this.setDriver({
    //         [`Has${this.widget.documentType}`]: true,
    //       });
    //     })
    //     .finally(() => {
    //       this.isLoading = false;
    //     });
    // },
    // deleteDocument() {
    //   this.isLoading = true;
    //   return DriverService.deleteDocument(
    //     this.Driver.Id,
    //     this.widget.documentType
    //   )
    //     .then(() => {
    //       this.setDriver({
    //         [`Has${this.widget.documentType}`]: false,
    //       });
    //       this.html = null;
    //     })
    //     .finally(() => {
    //       this.isLoading = false;
    //     });
    // },
    // showDocument() {
    //   this.$iframe.open({
    //     html: this.html,
    //     type: this.widget.documentType,
    //     // onSave: async ({ data, close }) => {
    //     //     if (data.close) {
    //     //         close();
    //     //     } else {
    //     //         this.saveDocument(data);
    //     //         close();
    //     //     }
    //     // },
    //   });
    // },
  },
  watch: {
    ready(newValue) {
      if (newValue) {
        this.init();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.CustomerDocumentsWidget__Component {
  // background-color: #eaf0f6;
  width: 100%;
  min-height: 60px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  & > div {
    display: flex;
    width: 100%;
  }

  .CustomerDocumentsWidget__Component__Item {
    border-radius: 3px;
    margin: 10px 0;
    padding: 10px;
    border: 1px solid #e4eaf1;
    position: relative;
    display: flex;
    flex-direction: row;
    font-weight: bold;
    background-color: #eef1f3;
    justify-content: space-around;

    span {
      font-weight: normal;
    }
    a {
      color: #06c;
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  button {
    border: none;
    background-color: transparent;
    text-align: center;
    width: 100%;
    color: #06c;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
}
</style>
