<template>
  <div class="DialogReportPaid__Component">
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">עדכון נהג</p>
        <button type="button" class="delete" @click="$emit('close')" />
      </header>
      <section class="modal-card-body">
        <!-- <h1>נא להזין כתובת חדשה</h1> -->
        <div class="DialogReportPaid__Component__Loading">
          <div v-if="isLoadingDriversList">
            <b-loading :is-full-page="false" v-model="isLoadingDriversList" :can-cancel="false" />
          </div>
          <b-field label="שם נהג">
            <b-input v-model="data.Name"></b-input>
          </b-field>
          <b-field label="תעודת זהות">
            <b-input v-model="data.IdNumber"></b-input>
          </b-field>
          <b-field label="מייל">
            <b-input v-model="data.Email"></b-input>
          </b-field>
          <b-field label="מספר ניתור">
            <b-input type="number" v-model="data.TrackerDriverId"></b-input>
          </b-field>
          <b-field label="מספר רשיון">
            <b-input v-model="data.LicenseNumber"></b-input>
          </b-field>
          <b-field label="עיר">
            <FieldInlineSelect :alert-not-exist="true" :hide-hover="true" label="" :clearable="true"
              :filterable="true" :null-is-value="true" placeholder="הקלד עיר" :options="[]"
              :fetch-options="onSearchCities" @onChange="onChange" :reduce="(item) => item.Text" v-model="data.City" />
          </b-field>
          <b-field label="רחוב">
            <FieldInlineSelect :readOnly="!data.City" :alert-not-exist="true" :hide-hover="true" label=""
              :clearable="true" :filterable="true" :null-is-value="true" placeholder="הקלד רחוב" :options="[]"
              :fetch-options="onSearchCityAddress" @onChange="onChange" :reduce="(item) => item"
              v-model="data.Street" />
          </b-field>
          <b-field label="מספר בית">
            <b-input v-model="data.HouseNumber"></b-input>
          </b-field>
          <b-field label="מיקוד">
            <b-input v-model="data.ZipCode"></b-input>
          </b-field>
        </div>
      </section>
      <footer class="modal-card-foot">
        <b-button :loading="isLoading" @click="save" label="שמור" type="is-primary" />
        <b-button :disabled="isLoading" label="סגור" @click="$emit('close')" />
      </footer>
    </div>
  </div>
</template>

<script>
// import DriverService from "@/services/DriverService";
import DriverService from "@/services/DriverService";
import FieldInlineSelect from "@/components/Fields/FieldInlineSelect.vue";
import { ToastProgrammatic as Toast } from "buefy";
import store from '../store';

export default {
  name: "DialogUpdateProfile",
  props: ["reports", "entityId", "currentDriver", "onSuccess", "driverId"],
  components: {
    FieldInlineSelect
  },
  computed: {
    items() {
      return this.reports ?? this.entityId;
    },
    profileId() {
      return store.state.profile.Id;
    }
  },
  created() {
    DriverService.getDriverAdmin(this.driverId).then((r) => {
      this.isLoadingDriversList = false;
      this.data = r.data;
      console.log(this.data);
    });
  },
  data() {
    return {
      isLoading: false,
      isLoadingDriversList: true,
      isDriverPaid: false,
      showAmount: false,
      amount: null,
      data: [],
      address: null
    };
  },
  methods: {
    setSelected(value) {
      this.driverId = value;
    },
    onChange() { },
    onSearchCities({ search, loading, setData, setValue }) {
      if (search.length >= 3) {
        loading(true);
        return (
          DriverService.searchCity(1, 1000, search)
        )
          .then((r) => {
            if (!r.data.Items.length) {
              setValue({ Value: -1, Text: search });
            } else {
              setData(r.data.Items.map((i) => ({ Value: i.Id, Text: i.Name })));
              this.model.Street = null;
            }
          })
          .finally(() => {
            loading(false);
          });
      }

      return null;
    },
    onSearchCityAddress({ search, loading, setData, setValue }) {
      if (search.length >= 3) {
        loading(true);
        return (
          DriverService.searchCityAddress(this.data.City, 1, 1000, search)
        )
          .then((r) => {
            if (!r.data.Items.length) {
              setValue({ Value: -1, Text: search });
            } else {
              setData(r.data.Items.map((i) => ({ Value: i.Id, Text: i.Name })));
            }
          })
          .finally(() => {
            loading(false);
          });
      }

      return null;
    },
    save() {
      this.isLoading = true;
      // const data = this.items.map((r) => ({
      //   ReportId: r,
      //   DriverId: this.driverId === 0 ? null : this.driverId,
      //   IsPaidByDriver: this.driverId && this.isDriverPaid,
      //   DriverAmount:
      //     this.isDriverPaid && this.showAmount && this.driverId
      //       ? this.amount
      //       : null,
      // }));
      const data = { ...this.data };
      data.Street = this.data.Street?.Text;
      DriverService.updateDriver(this.driverId, data, {
        headers: {
          "Profile-Id": store.state.report.Profile.Id
        }
      })
        .then(() => {
          this.$emit("close");
          if (this.onSuccess) this.onSuccess();
          Toast.open({
            type: "is-success",
            message: "הפעולה בוצעה!",
            duration: 4000,
          });
        })
        .catch(() => {
          Toast.open({
            type: "is-danger",
            message: "לא היה ניתן לבצע את הפעולה, יש לפנות לתמיכה הטכנית.",
            duration: 8000,
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>

<style lang="scss"></style>
