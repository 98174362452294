<template>
    <transition name="fade">
        <div v-if="ready" class="VehicleContractsWidget__Component">
            <div class="VehicleContractsWidget__Component__Item"
                v-for="item in VehicleContracts" :key="item.Id">
                <label>
                    רכב
                    <a :href="`/vehicles/${item.VehicleId}`"><span>{{item.PlateNumber}}</span></a>
                </label>
                <label>
                    מתאריך:
                    <FieldDateHourInline :value="item.StartDate" />
                </label>
                <label>
                    עד תאריך:
                    <FieldDateHourInline :value="item.EndDate" />
                </label>
                <router-link :to="{ name: 'DriverContract', params: { id: item.Id }}">
                    <span class="preview">הצג</span>
                </router-link>
            </div>
        </div>
    </transition>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import FieldDateHourInline from '../Fields/FieldDateHourInline.vue';

export default {
    name: 'DriverContractWidget',
    props: ['ready', 'namespace'],
    beforeCreate() {
        const { namespace } = this.$options.propsData;
        const { mapState } = createNamespacedHelpers(namespace);
        this.$options.computed = {
            ...mapState(['VehicleContracts']),
        };
    },
    methods: {
        init() {
            this.$emit('onReady', { titleCount: this.VehicleContracts.length });
        },
    },
    watch: {
        ready(newValue) {
            if (newValue) {
                this.init();
            }
        },
        VehicleContracts(newValue) {
            this.$emit('onReady', { titleCount: newValue?.length });
        }
    },
    components: {
        FieldDateHourInline,
    },
};
</script>

<style lang="scss" scoped>
    .VehicleContractsWidget__Component {
        // background-color: #eaf0f6;
        width: 100%;

        .VehicleContractsWidget__Component__Item {
            border-radius: 3px;
            margin: 10px 0;
            padding: 10px;
            border: 1px solid #e4eaf1;
            position: relative;
            display: flex;
            flex-direction: column;
            font-weight: bold;
            background-color: #c1cdff66;

            span {
                font-weight: normal;
            }
            a {
                color: #06c;
                cursor: pointer;
                &:hover {
                    text-decoration: underline;
                }
            }
        }
        .preview {
            position: absolute;
            left: 10px;
            top: 10px;
            color: #57B7CA;
            cursor: pointer;
            font-weight: 500 !important;

            &:hover {
                text-decoration: underline;
            }
        }
    }
</style>
