var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "fade" } }, [
    !_vm.isInitiating
      ? _c("div", { staticClass: "AccidentMyVehicleWidget__Component" }, [
          _vm.ready
            ? _c(
                "div",
                { staticClass: "files" },
                _vm._l(_vm.MyVehicle, function(image, index) {
                  return _c("div", { key: index, staticClass: "file" }, [
                    _c("img", {
                      attrs: {
                        width: "100",
                        height: "100",
                        src: image.ImageUrl,
                        alt: ""
                      }
                    }),
                    _c("span", [
                      _vm._v(_vm._s(_vm.getImageType(image.ImageType)))
                    ])
                  ])
                }),
                0
              )
            : _vm._e()
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }