<template>
  <div class="TransferStatuses__Widget" v-if="ready && filteredTransferStatusEvents.length">
    <div v-for="(status, index) in filteredTransferStatusEvents" class="content" :key="index">
      <div class="transfer-header">
        <div class="transfer-info">
          <span><b>מ:</b> {{ status.From }}</span>
          <span><b>ל:</b> {{ status.To }}</span>
        </div>
        <div class="icon">
          <b-button :loading="isLoading" @click.stop="preview(status)">
            <b-icon icon="download-circle" />
          </b-button>
        </div>
      </div>
      <div v-for="(event, idx) in status.Events" :key="idx">
        <div>
          <span>{{ event.Date | date }}</span> -
          <span>{{ event.TransferStatus.Name }}</span>
        </div>
        <div>
          עודכן ע"י:
          <span> {{ event.UpdatingUserName ? event.UpdatingUserName : '-' }} </span>
        </div>
        <div v-if="event.BotError">
          שגיאה מהבוט:
          <span> {{ event.BotError }} </span>
        </div>
        <div class="reference" v-if="event.Reference">{{ event.Reference }}</div>
      </div>
    </div>
  </div>
  <h1 v-else>אין נתוני הסבה</h1>
</template>

<script>
import FilesService from "@/services/FilesService";
import VueIframeWritable from "@/components/VueIframeWritable.vue";
import LeasingCompaniesService from "@/services/LeasingCompaniesService";
import { saveAs } from "file-saver";
import { ToastProgrammatic as Toast } from "buefy";
import moment from 'moment';
import printer from "../../plugins/printer";

export default {
  name: 'TransferStatusesCompanyWidget',
  props: ['ready', 'namespace', 'field'],
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {
    filteredTransferStatusEvents() {
      const transferStatusEvents = this.$store.state.report.Transfers;
      if (transferStatusEvents) {
        const statuses = transferStatusEvents.slice(0);
        return statuses;
      }
      return [];
      // statuses.forEach((status) => {
      //   status.Events.sort((a, b) => new Date(a.Date) - new Date(b.Date));
      // });
    },
  },
  filters: {
    date(value) {
      const date = moment(value, 'YYYY-MM-DDThh:mm');
      return date.format('DD/MM/YYYY HH:mm');
    },
  },
  methods: {
    init() {
      this.$emit('onReady');
    },
    preview(status) {
      const loadingComponent = this.$buefy.loading.open();
      const scrollPosition = document.body.querySelector('.table-wrapper')?.scrollTop;
      LeasingCompaniesService.printTransfersByBatchItemId([status.Id])
        .then((r) => {
          this.$iframe.open({
            html: r.data?.Html,
            component: VueIframeWritable,
            onSave: async ({ close, loading }) => {
              loading(true);
              this.downloadFile(this.value).finally(() => {
                loading(false);
                close();
              });
            },
            onPrint: async ({ loading }) => {
              loading(true);
              printer.printHtml(r.data.Html, scrollPosition);
              loading(false);
            },
          });
        })
        .finally(() => {
          loadingComponent.close();
        });
    },
    downloadFile(url) {
      this.isLoading = true;
      return FilesService.getTransferPdf(url, {
        // headers: { "Profile-Id": this.item.Owner.Id },
      })
        .then((r) => {
          if (r.data) {
            const blob = new Blob([r.data], { type: "application/pdf" });
            saveAs(blob, "הסבה.pdf");
          }
        })
        .catch(() => {
          Toast.open({
            type: "is-danger",
            message: "המערכת נתקלה בבעיה! יש לנסות שוב או לפנות לתמיכה הטכנית.",
            position: "is-bottom",
            duration: 8000,
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
  watch: {
    ready(newValue) {
      if (newValue) {
        this.init();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.TransferStatuses__Widget {
  width: 100%;

  .content {
    background-color: #f3f3f3;
    padding: 2px 5px;
    border-radius: 3px;
    margin: 9px 0;
    display: flex;
    flex-direction: column;

    &:nth-child(2n+1) {
      background-color: #eaf0f6
    }

    span {
      padding-left: 5px;
    }

    .reference {
      padding-left: 5px;
      margin: 5px 0px;
      border-top: 1px solid #5d5d5d38;
    }
  }

  .transfer-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .transfer-info {
    display: flex;
    flex-direction: column;
  }

  button {
    border: none;
    background: transparent;
  }

}
</style>
