var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-button",
        { attrs: { loading: _vm.loading }, on: { click: _vm.fetch } },
        [_vm._v("בקש תמונות")]
      ),
      _vm.loading
        ? _c("div", [
            _c("label", [_vm._v("בקשה זו עשויה להימשך עד מספר דקות...")]),
            _c("br"),
            _c("label", [_vm._v("נא התאזר בסבלנות")])
          ])
        : _vm._e(),
      _c(
        "div",
        { staticClass: "images" },
        _vm._l(_vm.photos, function(item, index) {
          return _c("div", { key: index, staticClass: "image" }, [
            _c("img", {
              attrs: { src: _vm.getImgUrl(item), alt: "" },
              on: {
                click: function($event) {
                  return _vm.presentImage(item)
                }
              }
            })
          ])
        }),
        0
      ),
      _vm.isPresentImage
        ? _c("div", { staticClass: "present-image-container" }, [
            _c(
              "button",
              {
                staticClass: "close-btn",
                on: {
                  click: function($event) {
                    _vm.isPresentImage = false
                  }
                }
              },
              [_c("b-icon", { attrs: { icon: "close", size: "is-medium" } })],
              1
            ),
            _c("img", {
              staticClass: "present-image",
              attrs: { src: _vm.currUrl, alt: "" }
            })
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }