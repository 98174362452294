var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.ready
    ? _c(
        "div",
        { staticClass: "ReportActivitiesWidget__Component" },
        [
          _vm._l(_vm.model, function(item, index) {
            return _c(
              "div",
              {
                key: index,
                staticClass: "ReportActivitiesWidget__Component__Activity",
                class: { complete: item.IsComplete },
                on: {
                  click: function($event) {
                    return _vm.goToEntity(item)
                  }
                }
              },
              [
                _c("b-icon", {
                  attrs: { icon: item.IsComplete ? "check-circle" : "" }
                }),
                _c("b-icon", {
                  attrs: {
                    icon:
                      item.ItemType === 1 ? "message-bulleted" : "calendar-plus"
                  }
                }),
                _c("div", {
                  style: [
                    item.IsComplete ? { "text-decoration": "line-through" } : ""
                  ],
                  domProps: { innerHTML: _vm._s(_vm.itemText(item)) }
                })
              ],
              1
            )
          }),
          !_vm.model.length
            ? _c("div", [_vm._v("לא קיימות פעילויות")])
            : _vm._e()
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }