<template>
  <div>
    {{text}}
  </div>
</template>

<script>
export default {
    name: 'TextWidget',
    props: ['data'],
    data() {
        return {
            text: null,
        };
    },
    mounted() {
      // this.data.request().then((r) => {
      //   this.text = r;
      //   this.$emit('onReady', true);
      // });
    },
};
</script>

<style>

</style>
