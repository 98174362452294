<template>
    <transition name="fade">
        <div class="VehicleContractDocumentsWidget__Component" v-if="!isInitiating">
            <div v-if="ready">
                <button  @click="showDocument"
                    v-if="show">הצג מסמך</button>
                <button  @click="deleteDocument"
                    v-if="show">מחק מסמך</button>
                <button @click="showDocument"
                     v-if="!hasDocument && !isLoading">הוסף מסמך</button>
            </div>
            <b-loading :is-full-page="false" v-model="isLoading" :can-cancel="false"></b-loading>
        </div>
    </transition>
</template>

<script>
import { mapMutations } from 'vuex';
import LeasingCompaniesService from '../../services/LeasingCompaniesService';

export default {
    name: 'VehicleContractDocumentsWidget',
    props: ['ready', 'namespace', 'query', 'widget'],
    computed: {
        show() {
            return this.hasDocument && !this.isInitiating && !this.isLoading;
        },
        hasDocument() {
            return this.$store.state.contract[`Has${this.widget.documentType}`];
        },
        contractId() {
            return this.$store.state.contract.Id;
        },
    },
    data() {
        return {
            html: null,
            isInitiating: true,
            isLoading: false,
        };
    },
    methods: {
        ...mapMutations('contract', ['setContract']),
        saveDocument(data) {
            this.isLoading = true;
            return LeasingCompaniesService
                .putVehicleDocument(this.contractId, data.type, { Html: data.html })
                .then(() => {
                    this.html = data.html;
                    this.setContract({
                        [`Has${this.widget.documentType}`]: true,
                    });
                }).finally(() => {
                    this.isLoading = false;
                });
        },
        deleteDocument() {
            this.isLoading = true;
             return LeasingCompaniesService
                .deleteVehicleDocument(this.contractId, this.widget.documentType)
                .then(() => {
                    this.setContract({
                        [`Has${this.widget.documentType}`]: false,
                    });
                    this.html = null;
                }).finally(() => {
                    this.isLoading = false;
                });
        },
        showDocument() {
            this.$iframe.open({
                html: this.html,
                type: this.widget.documentType,
                onSave: async ({ data, close }) => {
                    if (data.close) {
                        close();
                    } else {
                        this.saveDocument(data);
                        close();
                    }
                },
            });
        },
        async init() {
            if (this.widget.documentType) {
                if (this.hasDocument) {
                 LeasingCompaniesService
                    .getVehicleDocument(this.contractId, this.widget.documentType)
                        .then((r) => {
                            this.html = r.data;
                        })
                        .finally(() => {
                            this.$emit('onReady');
                            this.isInitiating = false;
                        });
                } else {
                    this.$emit('onReady');
                    this.isInitiating = false;
                }
            }
        },
    },
    watch: {
        ready(newValue) {
            if (newValue) {
                this.init();
            }
        },
    },
};
</script>

<style lang="scss" scoped>
    .VehicleContractDocumentsWidget__Component {
        // background-color: #eaf0f6;
        width: 100%;
        min-height: 60px;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;

        & > div {
            display: flex;
            width: 100%;
        }

        .CustomerDocumentsWidget__Component__Item {
            border-radius: 3px;
            margin: 10px 0;
            padding: 10px;
            border: 1px solid #e4eaf1;
            position: relative;
            display: flex;
            flex-direction: row;
            font-weight: bold;
            background-color: #eef1f3;
            justify-content: space-around;

            span {
                font-weight: normal;
            }
            a {
                color: #06c;
                cursor: pointer;
                &:hover {
                    text-decoration: underline;
                }
            }
        }
        button {
            border: none;
            background-color: transparent;
            text-align: center;
            width: 100%;
            color: #06c;
            cursor: pointer;

            &:hover {
                text-decoration: underline;
            }
        }
    }
</style>
