var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.ready
    ? _c(
        "div",
        { staticClass: "ReportStatusesWidget__Component" },
        [
          _vm._l(_vm.StatusEvents, function(item, index) {
            return _c(
              "div",
              {
                key: index,
                staticClass: "ReportStatusesWidget__Component__Status"
              },
              [
                _c("label", [
                  _c("span", [
                    _vm._v(
                      _vm._s(item.Status && item.Status.Name) +
                        " " +
                        _vm._s(_vm._f("date")(item.Date)) +
                        " "
                    )
                  ])
                ]),
                _c("label", [
                  _c("p", [
                    _vm._v(
                      'עודכן ע"י: ' +
                        _vm._s(
                          item.UpdatingUserName ? item.UpdatingUserName : "-"
                        )
                    )
                  ])
                ])
              ]
            )
          }),
          !_vm.StatusEvents.length
            ? _c("div", [_vm._v(" לא קיים סטטוס ")])
            : _vm._e()
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }