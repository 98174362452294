var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "fade" } }, [
    _vm.ready && _vm.contract
      ? _c(
          "div",
          { staticClass: "DriverContractForVehicleWidget__Component" },
          [
            _vm.contract.length
              ? _c(
                  "div",
                  {
                    staticClass:
                      "DriverContractForVehicleWidget__Component__Item"
                  },
                  _vm._l(_vm.contract, function(item) {
                    return _c("div", { key: item.Id }, [
                      _c(
                        "label",
                        [
                          _vm._v(" נהג: "),
                          _c(
                            "router-link",
                            {
                              attrs: {
                                to: {
                                  name: "Driver",
                                  params: { id: item.Driver.Id }
                                }
                              }
                            },
                            [_vm._v(" " + _vm._s(item.Driver.Name) + " ")]
                          )
                        ],
                        1
                      ),
                      _c("label", [
                        _vm._v(" רכב: "),
                        _c("span", [_vm._v(_vm._s(item.Vehicle.Name) + " ")])
                      ]),
                      _c(
                        "label",
                        [
                          _vm._v(" מתאריך: "),
                          _c("FieldDateHourInline", {
                            attrs: { value: item.StartDate }
                          })
                        ],
                        1
                      ),
                      _c(
                        "label",
                        [
                          _vm._v(" עד תאריך: "),
                          _c("FieldDateHourInline", {
                            attrs: { value: item.EndDate }
                          })
                        ],
                        1
                      )
                    ])
                  }),
                  0
                )
              : _c("div", [_vm._v("לא קיים חוזי נהג")])
          ]
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }