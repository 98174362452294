var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "Activity__Item" }, [
    _c("div", { staticClass: "Activity__Item__Box" }, [
      _c("div", { staticClass: "Activity__Item__Box__Content" }, [
        _c(
          "div",
          {
            staticClass: "Activity__Item__Box__Content__Slot",
            on: { click: _vm.toggleOpen }
          },
          [
            _c(
              "div",
              {
                staticClass: "Activity__Item__Box__Content__Actions",
                on: {
                  click: function($event) {
                    $event.stopPropagation()
                  }
                }
              },
              [
                _c(
                  "span",
                  { staticClass: "Activity__Item__Box__Content__Date" },
                  [_vm._v(_vm._s(_vm.parsedDate))]
                ),
                _vm.isOpen && _vm.item.ItemType !== 3
                  ? _c(
                      "b-dropdown",
                      {
                        attrs: { "aria-role": "list", animation: "" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "trigger",
                              fn: function(ref) {
                                var active = ref.active
                                return [
                                  _vm.isAdmin
                                    ? _c("b-button", {
                                        attrs: {
                                          label: "פעולות",
                                          type: "is-primary",
                                          "icon-right": active
                                            ? "menu-up"
                                            : "menu-down"
                                        }
                                      })
                                    : _vm._e()
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          2011016708
                        )
                      },
                      [
                        _c(
                          "b-dropdown-item",
                          {
                            attrs: { "aria-role": "listitem" },
                            on: { click: _vm.deleteItem }
                          },
                          [_vm._v("מחיקה")]
                        )
                      ],
                      1
                    )
                  : _vm._e()
              ],
              1
            ),
            _c("div", { staticClass: "Activity__Item__Box__Content__Text" }, [
              _vm.item.ItemType !== 3
                ? _c(
                    "div",
                    {
                      staticClass: "Activity__Item__Box__Content__Text__Icon",
                      class: { open: _vm.isOpen }
                    },
                    [_c("b-icon", { attrs: { icon: "chevron-up" } })],
                    1
                  )
                : _vm._e(),
              _c("strong", [
                _vm._v(_vm._s(_vm.getType(_vm.item.ItemType)) + " ")
              ]),
              _vm.item.ItemType !== 3
                ? _c("span", [_vm._v(' ע"י ')])
                : _vm._e(),
              _vm.item.ItemType !== 3
                ? _c("span", { staticClass: "user" }, [
                    _vm._v(_vm._s(_vm.item.CreatorName))
                  ])
                : _vm._e(),
              _vm.item.ItemType !== 3 &&
              _vm.item.Comments &&
              _vm.item.Comments.length &&
              !_vm.isOpen
                ? _c(
                    "div",
                    {
                      staticClass:
                        "Activity__Item__Box__Content__Text__Comments"
                    },
                    [
                      _c("b-icon", {
                        attrs: { icon: "comment-processing", size: "is-small" }
                      }),
                      _c("span", [
                        _vm._v(
                          _vm._s(_vm.item.Comments && _vm.item.Comments.length)
                        )
                      ])
                    ],
                    1
                  )
                : _vm._e(),
              _vm.item.ItemType === 3
                ? _c("div", [
                    _vm._v(
                      " סטטוס שונה ל " +
                        _vm._s(_vm.item.Title) +
                        " - " +
                        _vm._s(_vm.item.Body) +
                        " "
                    )
                  ])
                : _vm._e()
            ])
          ]
        ),
        _vm.item.ItemType !== 3
          ? _c(
              "div",
              { staticClass: "Activity__Item__Box__Content__Component" },
              [
                _c(_vm.componentType, {
                  tag: "component",
                  attrs: {
                    entityType: _vm.entityType,
                    entityId: _vm.entityId,
                    item: _vm.item,
                    isOpen: _vm.isOpen
                  }
                })
              ],
              1
            )
          : _vm._e()
      ]),
      _vm.isOpen
        ? _c(
            "div",
            { staticClass: "Activity__Item__Box__Comments" },
            [
              _c("ActivityComments", {
                attrs: {
                  comments: _vm.item.Comments,
                  entityType: _vm.entityType,
                  entityId: _vm.entityId,
                  id: _vm.item.Id
                }
              })
            ],
            1
          )
        : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }