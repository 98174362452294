<template>
  <div>
    Todo
  </div>
</template>

<script>

export default {
    name: 'ContractWidget',
    props: ['ready'],

    methods: {
        init() {
            this.$emit('onReady', true);
        },
    },
    watch: {
        ready(newValue) {
            if (newValue) {
                this.init();
            }
        },
    },
};
</script>

<style>

</style>
