var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.ready
    ? _c(
        "div",
        { staticClass: "TransferStatuses__Widget" },
        [
          _c(
            "div",
            { staticClass: "icon" },
            [
              _c(
                "b-button",
                {
                  attrs: { loading: _vm.isLoading },
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      return _vm.preview()
                    }
                  }
                },
                [_c("b-icon", { attrs: { icon: "download-circle" } })],
                1
              )
            ],
            1
          ),
          _vm._l(_vm.filteredTransferStatusEvents, function(item, index) {
            return _c("label", { key: index }, [
              _c("div", { staticClass: "transfer-header" }, [
                _c("div", [
                  _c("span", [_vm._v(_vm._s(_vm._f("date")(item.Date)))]),
                  _vm._v(" - "),
                  _c("span", [_vm._v(_vm._s(item.TransferStatus.Name))])
                ])
              ]),
              _c("div", [
                _vm._v(' עודכן ע"י: '),
                _c("span", [
                  _vm._v(
                    " " +
                      _vm._s(
                        item.UpdatingUserName ? item.UpdatingUserName : "-"
                      ) +
                      " "
                  )
                ])
              ]),
              item.BotError
                ? _c("div", [
                    _vm._v(" שגיאה מהבוט: "),
                    _c("span", [_vm._v(" " + _vm._s(item.BotError) + " ")])
                  ])
                : _vm._e(),
              item.Reference
                ? _c("span", { staticClass: "reference" }, [
                    _vm._v(_vm._s(item.Reference))
                  ])
                : _vm._e()
            ])
          }),
          !_vm.filteredTransferStatusEvents.length
            ? _c("h1", [_vm._v("אין נתוני הסבה")])
            : _vm._e()
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }