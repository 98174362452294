import { render, staticRenderFns } from "./TollRoadsQueryWidget.vue?vue&type=template&id=73e2d6e4&scoped=true&"
import script from "./TollRoadsQueryWidget.vue?vue&type=script&lang=js&"
export * from "./TollRoadsQueryWidget.vue?vue&type=script&lang=js&"
import style0 from "./TollRoadsQueryWidget.vue?vue&type=style&index=0&id=73e2d6e4&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "73e2d6e4",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\Mobaxe\\Betterway.vms\\Betterway.VMS.web\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('73e2d6e4')) {
      api.createRecord('73e2d6e4', component.options)
    } else {
      api.reload('73e2d6e4', component.options)
    }
    module.hot.accept("./TollRoadsQueryWidget.vue?vue&type=template&id=73e2d6e4&scoped=true&", function () {
      api.rerender('73e2d6e4', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/Widgets/TollRoadsQueryWidget.vue"
export default component.exports