<template>
  <div class="TransferStatuses__Widget" v-if="ready">
        <div class="icon">
          <b-button :loading="isLoading" @click.stop="preview()">
            <b-icon icon="download-circle" />
          </b-button>
        </div>
    <label v-for="(item, index) in filteredTransferStatusEvents" :key="index">
      <div class="transfer-header">
        <div>
          <span>{{ item.Date | date }}</span> -
          <span>{{ item.TransferStatus.Name }}</span>
        </div>
      </div>
      <div>
        עודכן ע"י:
        <span> {{ item.UpdatingUserName ? item.UpdatingUserName : '-' }} </span>
      </div>
      <div v-if="item.BotError">
        שגיאה מהבוט:
        <span> {{ item.BotError }} </span>
      </div>
      <span class="reference" v-if="item.Reference">{{ item.Reference }}</span>
    </label>
    <h1 v-if="!filteredTransferStatusEvents.length">אין נתוני הסבה</h1>
  </div>
</template>

<script>
import FilesService from "@/services/FilesService";
import VueIframeWritable from "@/components/VueIframeWritable.vue";
import DriverService from "@/services/DriverService";
import { saveAs } from "file-saver";
import { ToastProgrammatic as Toast } from "buefy";
import moment from 'moment';
import printer from "../../plugins/printer";

export default {
  name: 'TransferStatusesDriverWidget',
  props: ['ready', 'namespace'],
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {
    filteredTransferStatusEvents() {
      const transferStatusEvents = this.$store.state[this.namespace].DriverTransferStatusEvents;
      if (transferStatusEvents) {
        const statuses = transferStatusEvents.slice(0);
        statuses.sort((a, b) => new Date(a.Date) - new Date(b.Date));
        return statuses;
      }
      return [];
    },
  },
  filters: {
    date(value) {
      const date = moment(value, 'YYYY-MM-DDThh:mm');
      return date.format('DD/MM/YYYY HH:mm');
    },
  },
  methods: {
    init() {
      this.$emit('onReady');
    },
    preview() {
      const loadingComponent = this.$buefy.loading.open();
      const scrollPosition = document.body.querySelector('.table-wrapper')?.scrollTop;
      DriverService.printTransfers([this.$store.state.report.Id])
        .then((r) => {
          this.$iframe.open({
            html: r.data?.Html,
            component: VueIframeWritable,
            onSave: async ({ close, loading }) => {
              loading(true);
              this.downloadFile(this.value).finally(() => {
                loading(false);
                close();
              });
            },
            onPrint: async ({ loading }) => {
              loading(true);
              printer.printHtml(r.data.Html, scrollPosition);
              loading(false);
            },
          });
        })
        .finally(() => {
          loadingComponent.close();
        });
    },
    downloadFile(url) {
      this.isLoading = true;
      return FilesService.getTransferPdf(url, {
        // headers: { "Profile-Id": this.item.Owner.Id },
      })
        .then((r) => {
          if (r.data) {
            const blob = new Blob([r.data], { type: "application/pdf" });
            saveAs(blob, "הסבה.pdf");
          }
        })
        .catch(() => {
          Toast.open({
            type: "is-danger",
            message: "המערכת נתקלה בבעיה! יש לנסות שוב או לפנות לתמיכה הטכנית.",
            position: "is-bottom",
            duration: 8000,
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
  watch: {
    ready(newValue) {
      if (newValue) {
        this.init();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.TransferStatuses__Widget {
  width: 100%;
  position: relative;

  label {
    background-color: #eaf0f6;
    padding: 2px 5px;
    border-radius: 3px;
    margin: 9px 0;
    display: flex;
    flex-direction: column;

    &:nth-child(2n+1) {
      background-color: #f3f3f3;
    }

    span {
      // font-weight: 600;
      padding-left: 5px;
    }

    .reference {
      margin: 5px 0px;
      border-top: 1px solid #5d5d5d38;
    }
  }

  .transfer-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .transfer-info {
    display: flex;
    flex-direction: column;
  }

  button {
    border: none;
    background: transparent;
  }
  .icon {
    position: absolute;
    left: 10px;
    top: 10px;
  }
}</style>
