import DriverService from '@/services/DriverService';
import EditReportPaymentWidget from '@/components/Widgets/EditReportPaymentWidget.vue';
import ReportStatusManageWidget from '@/components/Widgets/ReportStatusManageWidget.vue';
import ReportAlertsManageWidget from '@/components/Widgets/ReportAlertsManageWidget.vue';
import ReportDeliveryManageWidget from '@/components/Widgets/ReportDeliveryManageWidget.vue';
import DialogReportDriverAssign from '@/components/DialogReportDriverAssign.vue';
import VehicleAddContract from '@/components/Widgets/VehicleAddContract.vue';
// import VehicleOwnershipContracts from '@/components/Widgets/VehicleOwnershipContracts.vue';
import VehicleCurrentOwnership from '@/components/Widgets/VehicleCurrentOwnership.vue';
import VehiclePreviousOwnerships from '@/components/Widgets/VehiclePreviousOwnerships.vue';
import DriverAddContract from '@/components/Widgets/DriverAddContract.vue';

const widgets = {
    Company: 'Company',
    ReportPayment: {
        type: 'ReportPaymentWidget',
        title: 'תשלום',
        data: (slot) => ({
            request: () => Promise.resolve({
                IsPaid: slot.IsPaid,
                IsPaidByDriver: slot.IsPaidByDriver,
                IsPayable: slot.IsPayable,
                OverpaidAmount: slot.OverpaidAmount,
                PartialPaidAmount: slot.OverpaidAmount,
                PaymentDate: slot.PaymentDate,
                PaymentReferenceNumber: slot.PaymentReferenceNumber,
                PaymentUrl: slot.PaymentUrl,
            }),
            mapResponse: (data) => ({ data }),
        }),
        editComponent: EditReportPaymentWidget,
    },
    Vehicle: {
        type: 'VehicleWidget',
        title: 'רכב',
        data: (slot) => ({
            request: () => Promise.resolve(slot.Vehicle.Name),
            mapResponse: (data) => ({ data }),
        }),
    },
    Driver: {
        type: 'DriverWidget',
        title: 'נהג',
        data: (slot) => ({
            request: () => (slot.Driver ? DriverService
                .getDriver(slot.Driver?.Id)
                .then(this.mapResponse) : Promise.resolve({})),
            mapResponse: (r) => ({ data: r.data }),
        }),
        modal: DialogReportDriverAssign,
    },
    Profile: {
        type: 'ClientWidget',
        title: 'לקוח',
        data: (slot) => ({
            request: () => Promise.resolve(slot.ProfileName),
            mapResponse: (data) => ({ data }),
        }),
    },
    Contract: {
        type: 'ContractWidget',
        title: 'חוזה',
    },
    DriverContracts: {
        type: 'DriverContractWidget',
        title: 'חוזה נהג',
        multiple: true,
        addable: true,
        modal: DriverAddContract,
    },
    ReportPhotos: {
        type: 'ReportPhotosWidget',
        title: 'תמונות',
        loadingType: 'photos',
    },
    ReportStatuses: {
        type: 'ReportStatusesWidget',
        title: 'סטטוסים',
        multiple: true,
        modal: ReportStatusManageWidget,
    },
    ReportActivities: {
        type: 'ReportActivitiesWidget',
        title: 'פעילויות',
        multiple: true,
    },
    ReportHistory: {
        type: 'ReportHistoryWidget',
        title: 'היסטורית אירועים',
    },
    ReportDelivery: {
        type: 'ReportDeliveryWidget',
        title: 'אופן מסירה',
        multiple: true,
        modal: ReportDeliveryManageWidget,
    },
    ReportAlerts: {
        type: 'ReportAlertsWidget',
        title: 'התראות',
        multiple: true,
        modal: ReportAlertsManageWidget,
    },
    DriverContractForReport: {
        type: 'DriverContractForReportWidget',
        title: 'חוזה נהג',
    },
    DriverContractForVehicle: {
        type: 'DriverContractForVehicleWidget',
        title: 'חוזי נהג',
        multiple: true
    },
    VehicleContractForReport: {
        type: 'VehicleContractForReportWidget',
        title: 'חוזה רכב',
    },
    DriverTransfer: 'DriverTransfer',
    CompanyTransfer: 'CompanyTransfer',
    VehicleCurrentOwnership: {
        type: 'VehicleCurrentOwnerWidget',
        title: 'בעלות נוכחית',
        modal: VehicleCurrentOwnership,
        isAdminEditable: true
    },
    VehicleOwners: {
        type: 'VehicleOwnersWidget',
        title: 'היסטורית בעלויות',
        multiple: true,
        modal: VehiclePreviousOwnerships,
        isAdminEditable: true
    },
    VehicleOwnershipContracts: {
        type: 'VehicleOwnershipContracts',
        title: 'חוזי בעלות',
        multiple: true,
    },
    VehicleContracts: {
        type: 'VehicleContractsWidget',
        title: 'חוזים',
        multiple: true,
        addable: true,
        modal: VehicleAddContract,
    },
    VehicleContractDocuments: {
        type: 'VehicleContractDocumentsWidget',
        title: 'טפסי הסבה',
    },
    DriverContractDocuments: {
        type: 'DriverContractDocumentsWidget',
        title: 'טפסי הסבה',
    },
    Reports: {
        type: 'ReportsQueryWidget',
        title: 'דוחות פתוחים',
        multiple: true,
    },
    VehiclesQuery: {
        type: 'VehiclesQueryWidget',
        title: 'רכבים',
        multiple: true,
    },
    TollRoadsDrives: {
        type: 'TollRoadsQueryWidget',
        title: 'נסיעות כבישי אגרה',
        multiple: true,
    },
    VehicleDrives: {
        type: 'VehicleDrivesWidget',
        title: 'נסיעות',
        multiple: true,
    },
    VehicleAccidents: {
        type: 'VehicleAccidentsWidget',
        title: 'תאונות',
        addable: true,
    },
    ReportDetailsWidget: {
        type: 'ReportDetailsWidget',
        title: 'נתוני דוח',
        addable: false,
    },
    TransferStatusesDriverWidget: {
        type: 'TransferStatusesDriverWidget',
        title: "סטטוס הסבת נהג",
        addable: false,
    },
    TransferStatusesCompanyWidget: {
        type: 'TransferStatusesCompanyWidget',
        title: 'סטטוס הסבת חברה',
    },
    TransferDocumentWidget: {
        type: 'PreviewDocumentWidget',
        title: 'מסמך הסבת חברה',
    },
    TransferDriverDocumentWidget: {
        type: 'PreviewDriverDocumentWidget',
        title: 'מסמך הסבת נהג',
    },
    TransferDocuments: {
        type: 'CustomerDocumentsWidget',
        title: 'טפסי הסבה',
    },
    DriverTransferform: {
        type: 'DriverDocumentsWidget',
        title: 'טפסי הסבה',
    },
    AccidentPdfFile: {
        type: 'AccidentPdfFileWidget',
        title: 'טופס דיווח תאונה',
    },
    AccidentVehicle: {
        type: 'AccidentVehicleWidget',
        title: 'הרכב שלי',
    },
};

export default widgets;
