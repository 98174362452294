var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "fade" } }, [
    _vm.ready && _vm.results != null
      ? _c("div", { staticClass: "VehicleReportsWidget__Component" }, [
          _vm.results == 0
            ? _c("div", [
                _vm._v(
                  " " +
                    _vm._s(_vm.widget.noData || "אין דוחות פתוחים לרכב זה") +
                    " "
                )
              ])
            : _vm._e(),
          _vm.results > 0
            ? _c(
                "div",
                [
                  _vm._l(_vm.items, function(item) {
                    return _c(
                      "div",
                      {
                        key: item.Id,
                        staticClass: "VehicleReportsWidget__Component__Item",
                        on: {
                          click: function($event) {
                            return _vm.goToRpeort(item.Id)
                          }
                        }
                      },
                      [
                        _c("span", [_vm._v(_vm._s(item.ReportNumber))]),
                        _c("span", [_vm._v(_vm._s(item.ProfileName))]),
                        _c("span", [
                          _vm._v(_vm._s(_vm.parseDate(item.DateTaken)))
                        ]),
                        _c("span", [_vm._v(_vm._s(item.Municipality))])
                      ]
                    )
                  }),
                  _c("button", { on: { click: _vm.showAll } }, [
                    _vm._v("הצג את כל הדוחות הפתוחים")
                  ])
                ],
                2
              )
            : _vm._e()
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }