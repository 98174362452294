var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "fade" } }, [
    _vm.ready
      ? _c(
          "div",
          { staticClass: "VehicleContractsWidget__Component" },
          _vm._l(_vm.VehicleContracts, function(item) {
            return _c(
              "div",
              {
                key: item.Id,
                staticClass: "VehicleContractsWidget__Component__Item"
              },
              [
                _c("label", [
                  _vm._v(" רכב "),
                  _c("a", { attrs: { href: "/vehicles/" + item.VehicleId } }, [
                    _c("span", [_vm._v(_vm._s(item.PlateNumber))])
                  ])
                ]),
                _c(
                  "label",
                  [
                    _vm._v(" מתאריך: "),
                    _c("FieldDateHourInline", {
                      attrs: { value: item.StartDate }
                    })
                  ],
                  1
                ),
                _c(
                  "label",
                  [
                    _vm._v(" עד תאריך: "),
                    _c("FieldDateHourInline", {
                      attrs: { value: item.EndDate }
                    })
                  ],
                  1
                ),
                _c(
                  "router-link",
                  {
                    attrs: {
                      to: { name: "DriverContract", params: { id: item.Id } }
                    }
                  },
                  [_c("span", { staticClass: "preview" }, [_vm._v("הצג")])]
                )
              ],
              1
            )
          }),
          0
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }