<template>
  <div class="DialogReportPaid__Component">
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">עדכון פרופיל</p>
        <button type="button" class="delete" @click="$emit('close')" />
      </header>
      <section class="modal-card-body">
        <!-- <h1>נא להזין כתובת חדשה</h1> -->
        <div class="DialogReportPaid__Component__Loading">
          <div v-if="isLoadingDriversList">
            <b-loading :is-full-page="false" v-model="isLoadingDriversList" :can-cancel="false" />
          </div>
          <b-field label="שם חברה">
            <b-input v-model="data.Name"></b-input>
          </b-field>
          <b-field label="ח.פ">
            <b-input v-model="data.IdNumber"></b-input>
          </b-field>
          <b-field label="מייל">
            <b-input v-model="data.Email"></b-input>
          </b-field>
          <b-field v-if="data.IsForeignCitizen" label="עיר">
            <b-input v-model="data.City"></b-input>
          </b-field>
          <b-field v-if="data.IsForeignCitizen" label="רחוב">
            <b-input v-model="data.Street"></b-input>
          </b-field>
          <FieldInlineSelect v-if="!data.IsForeignCitizen" :alert-not-exist="true" :hide-hover="true" label="עיר"
            :clearable="true" :filterable="true" :null-is-value="true" placeholder="הקלד עיר" :options="[]"
            :fetch-options="onSearchCities" @onChange="onChange" :reduce="(item) => item.Text" v-model="data.City" />

          <FieldInlineSelect v-if="!data.IsForeignCitizen" :readOnly="!data.City" :alert-not-exist="true"
            :hide-hover="true" label="רחוב" :clearable="true" :filterable="true" :null-is-value="true"
            placeholder="הקלד רחוב" :options="[]" :fetch-options="onSearchCityAddress" @onChange="onChange"
            :reduce="(item) => item" v-model="data.Street" />

          <b-field label="מספר בית">
            <b-input v-model="data.HouseNumber"></b-input>
          </b-field>
          <b-field label="מיקוד">
            <b-input v-model="data.ZipCode"></b-input>
          </b-field>
          <b-field label="תייר">
            <b-switch class="ltr" v-model="data.IsForeignCitizen">{{
              data.IsForeignCitizen ? "כן" : "לא"
            }}</b-switch>
          </b-field>
        </div>
      </section>
      <footer class="modal-card-foot">
        <b-button :loading="isLoading" @click="save" label="שמור" type="is-primary" />
        <b-button :disabled="isLoading" label="סגור" @click="$emit('close')" />
      </footer>
    </div>
  </div>
</template>

<script>
// import DriverService from "@/services/DriverService";
import ProfileService from "@/services/ProfileService";
import FieldInlineSelect from "@/components/Fields/FieldInlineSelect.vue";
import { ToastProgrammatic as Toast } from "buefy";
import DriverService from "../services/DriverService";

export default {
  name: "DialogReportDriverAssign",
  props: ["reports", "entityId", "currentDriver", "onSuccess", "profileId"],
  components: {
    FieldInlineSelect
  },
  computed: {
    items() {
      return this.reports ?? this.entityId;
    },
  },
  created() {
    ProfileService.get({
      headers: {
        "Profile-Id": this.profileId
      }
    }).then((r) => {
      this.isLoadingDriversList = false;
      this.data = r;
      console.log(this.data);
    });
  },
  data() {
    return {
      isLoading: false,
      isLoadingDriversList: true,
      driverId: null,
      isDriverPaid: false,
      showAmount: false,
      amount: null,
      data: [],
      address: null
    };
  },
  methods: {
    setSelected(value) {
      this.driverId = value;
    },
    onSearchCities({ search, loading, setData, setValue }) {
      if (search.length >= 3) {
        loading(true);
        return (
          DriverService.searchCity(1, 1000, search)
        )
          .then((r) => {
            if (!r.data.Items.length) {
              setValue({ Value: -1, Text: search });
            } else {
              setData(r.data.Items.map((i) => ({ Value: i.Id, Text: i.Name })));
              this.data.Street = null;
            }
          })
          .finally(() => {
            loading(false);
          });
      }

      return null;
    },
    onSearchCityAddress({ search, loading, setData, setValue }) {
      if (search.length >= 3) {
        loading(true);
        return (
          DriverService.searchCityAddress(this.data.City, 1, 1000, search)
        )
          .then((r) => {
            if (!r.data.Items.length) {
              setValue({ Value: -1, Text: search });
            } else {
              setData(r.data.Items.map((i) => ({ Value: i.Id, Text: i.Name })));
            }
          })
          .finally(() => {
            loading(false);
          });
      }

      return null;
    },
    onChange() { },
    save() {
      this.isLoading = true;
      // const data = this.items.map((r) => ({
      //   ReportId: r,
      //   DriverId: this.driverId === 0 ? null : this.driverId,
      //   IsPaidByDriver: this.driverId && this.isDriverPaid,
      //   DriverAmount:
      //     this.isDriverPaid && this.showAmount && this.driverId
      //       ? this.amount
      //       : null,
      // }));
      const data = { ...this.data };
      data.Street = this.data.Street?.Text;
      ProfileService.update(data, {
        headers: {
          "Profile-Id": this.profileId
        }
      })
        .then(() => {
          this.$emit("close");
          if (this.onSuccess) this.onSuccess();
          Toast.open({
            type: "is-success",
            message: "הפעולה בוצעה!",
            duration: 4000,
          });
        })
        .catch(() => {
          Toast.open({
            type: "is-danger",
            message: "לא היה ניתן לבצע את הפעולה, יש לפנות לתמיכה הטכנית.",
            duration: 8000,
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>

<style lang="scss"></style>
