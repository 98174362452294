<template>
    <transition name="fade">
        <div v-if="ready && Ownership" class="VehicleOwnersWidget__Component">
            <div class="VehicleOwnersWidget__Component__Item">
                <label>
                    בעלים:
                    <span>{{Ownership.Name}}</span>
                </label>
                <label>
                    מתאריך:
                    <FieldDateHourInline :value="Ownership.StartDate" />
                </label>
                <label>
                    עד תאריך:
                    <FieldDateHourInline :value="Ownership.EndDate" />
                </label>
            </div>
        </div>
        <div v-else> לא קיימת בעלות נוכחית</div>
    </transition>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import FieldDateHourInline from '../Fields/FieldDateHourInline.vue';

export default {
    name: 'VehicleCurrentOwnerWidget',
    props: ['ready', 'namespace'],
    beforeCreate() {
        const { namespace } = this.$options.propsData;
        const { mapState } = createNamespacedHelpers(namespace);
        this.$options.computed = {
            ...mapState(['Ownership']),
        };
    },
    methods: {
        init() {
            this.$emit('onReady');
        },
    },
    watch: {
        ready(newValue) {
            if (newValue) {
                this.init();
            }
        },
        Ownership() {
            this.$emit('onReady');
        },
    },
    components: {
        FieldDateHourInline,
    },
};
</script>

<style lang="scss" scoped>
    .VehicleOwnersWidget__Component {
        // background-color: #eaf0f6;
        width: 100%;

        .VehicleOwnersWidget__Component__Item {
            border-radius: 3px;
            margin: 10px 0;
            padding: 10px;
            border: 1px solid #e4eaf1;
            position: relative;
            display: flex;
            flex-direction: column;
            font-weight: bold;

            span {
                font-weight: normal;
            }
            a {
                color: #06c;
                cursor: pointer;
                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
</style>
