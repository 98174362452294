<template>
  <div class="ReportAlertWidget__Component" v-if="ready">
      <label v-for="item in Alerts" :key="item.Id">
        {{ item.Name }}
      </label>
      <div v-if="!Alerts.length">
        אין התראות
      </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

export default {
    name: 'ReportAlertsWidget',
    props: ['ready', 'namespace'],
    data() {
        return {
          model: [],
        };
    },
    beforeCreate() {
        const { namespace } = this.$options.propsData;
        const { mapState } = createNamespacedHelpers(namespace);
        this.$options.computed = {
            ...mapState(['Alerts']),
        };
    },
    methods: {
      init() {
        this.model = [...this.model, ...this.Alerts];
        this.$emit('onReady', {
          titleCount: this.model.length,
        });
      },
    },
    watch: {
      ready(newValue) {
          if (newValue) {
              this.init();
          }
      },
    },
};
</script>

<style lang="scss" scoped>
.ReportAlertWidget__Component {
  display: flex;
  flex-wrap: wrap;

  label {
    background-color: #ffc0c0;
    border-radius: 3px;
    padding: 2px 10px;
    margin: 5px 3px;
  }
}
</style>
