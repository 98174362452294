var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.ready && _vm.filteredTransferStatusEvents.length
    ? _c(
        "div",
        { staticClass: "TransferStatuses__Widget" },
        _vm._l(_vm.filteredTransferStatusEvents, function(status, index) {
          return _c(
            "div",
            { key: index, staticClass: "content" },
            [
              _c("div", { staticClass: "transfer-header" }, [
                _c("div", { staticClass: "transfer-info" }, [
                  _c("span", [
                    _c("b", [_vm._v("מ:")]),
                    _vm._v(" " + _vm._s(status.From))
                  ]),
                  _c("span", [
                    _c("b", [_vm._v("ל:")]),
                    _vm._v(" " + _vm._s(status.To))
                  ])
                ]),
                _c(
                  "div",
                  { staticClass: "icon" },
                  [
                    _c(
                      "b-button",
                      {
                        attrs: { loading: _vm.isLoading },
                        on: {
                          click: function($event) {
                            $event.stopPropagation()
                            return _vm.preview(status)
                          }
                        }
                      },
                      [_c("b-icon", { attrs: { icon: "download-circle" } })],
                      1
                    )
                  ],
                  1
                )
              ]),
              _vm._l(status.Events, function(event, idx) {
                return _c("div", { key: idx }, [
                  _c("div", [
                    _c("span", [_vm._v(_vm._s(_vm._f("date")(event.Date)))]),
                    _vm._v(" - "),
                    _c("span", [_vm._v(_vm._s(event.TransferStatus.Name))])
                  ]),
                  _c("div", [
                    _vm._v(' עודכן ע"י: '),
                    _c("span", [
                      _vm._v(
                        " " +
                          _vm._s(
                            event.UpdatingUserName
                              ? event.UpdatingUserName
                              : "-"
                          ) +
                          " "
                      )
                    ])
                  ]),
                  event.BotError
                    ? _c("div", [
                        _vm._v(" שגיאה מהבוט: "),
                        _c("span", [_vm._v(" " + _vm._s(event.BotError) + " ")])
                      ])
                    : _vm._e(),
                  event.Reference
                    ? _c("div", { staticClass: "reference" }, [
                        _vm._v(_vm._s(event.Reference))
                      ])
                    : _vm._e()
                ])
              })
            ],
            2
          )
        }),
        0
      )
    : _c("h1", [_vm._v("אין נתוני הסבה")])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }