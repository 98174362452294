var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "fade" } }, [
    _vm.ready
      ? _c(
          "div",
          { staticClass: "ReportDetailsWidget__Component" },
          [
            _c("div", [
              _c("div", { staticClass: "field" }, [
                _c("strong", [_vm._v("מספר דוח: ")]),
                _c(
                  "span",
                  [
                    _c(
                      "router-link",
                      { attrs: { to: "/reports/" + _vm.reportWidgetModal.Id } },
                      [_vm._v(" " + _vm._s(_vm.reportWidgetModal.ReportNumber))]
                    )
                  ],
                  1
                )
              ]),
              _c("div", { staticClass: "field" }, [
                _c("strong", [_vm._v("שולם: ")]),
                _c("span", [
                  _vm._v(_vm._s(_vm.reportWidgetModal.IsPaid ? "כן" : "לא"))
                ])
              ]),
              _c("div", { staticClass: "field" }, [
                _c("strong", [_vm._v("סטטוס: ")]),
                _c("span", [_vm._v(_vm._s(_vm.reportWidgetModal.Status))])
              ]),
              _c("div", { staticClass: "field" }, [
                _c("strong", [_vm._v("עיריה: ")]),
                _c("span", [
                  _vm._v(_vm._s(_vm.reportWidgetModal.Municipality.Name))
                ])
              ]),
              _c("div", { staticClass: "field" }, [
                _c("strong", [_vm._v("כתובת: ")]),
                _c("span", [_vm._v(_vm._s(_vm.reportWidgetModal.Address))])
              ]),
              _c("div", { staticClass: "field" }, [
                _c("strong", [_vm._v("סכום לתשלום: ")]),
                _c("span", [
                  _vm._v(_vm._s(_vm.reportWidgetModal.CurrentAmount) + " ₪")
                ])
              ]),
              _c("div", { staticClass: "field" }, [
                _c("strong", [_vm._v("סיבת הדוח: ")]),
                _c("span", [_vm._v(_vm._s(_vm.reportWidgetModal.Reason))])
              ])
            ]),
            _c(
              "router-link",
              {
                attrs: {
                  to: {
                    name: "Report",
                    params: { id: _vm.reportWidgetModal.Id }
                  }
                }
              },
              [_c("span", { staticClass: "preview" }, [_vm._v("הצג")])]
            )
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }