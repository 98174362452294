<template>
  <div class="Activity__Component">
    <div class="Activity__Component__Tabs">
      <div
        class="Activity__Component__Tabs__Item"
        @click="tab = 1"
        :class="{ active: tab === 1 }"
      >
        פעילויות
      </div>
      <div
        class="Activity__Component__Tabs__Item"
        @click="loadTasks()"
        :class="{ active: tab === 2 }"
      >
        משימות
      </div>
      <div
        class="Activity__Component__Tabs__Item"
        @click="tab = 3"
        :class="{ active: tab === 3 }"
      >
        הערות
      </div>
    </div>
    <div class="Activity__Component__TabsContent">
      <new-activity
        ref="noteActivity"
        v-if="showNewNote"
        type="Note"
        @onClose="close"
        @onSave="saveNote"
      />
      <new-activity
        ref="taskActivity"
        v-if="showNewTask"
        type="Task"
        show-title="true"
        @onClose="closeTask"
        @onSave="saveTask"
      />
      <div v-show="tab === 1">
        <template v-if="loading">
          <div class="loading">
            <ContentLoader viewBox="0 0 700 700" width="700" height="350">
              <rect x="4" y="8" rx="3" ry="3" width="7" height="288" />
              <rect x="6" y="289" rx="3" ry="3" width="669" height="8" />
              <rect x="670" y="9" rx="3" ry="3" width="6" height="285" />
              <rect x="55" y="42" rx="16" ry="16" width="274" height="216" />
              <rect x="412" y="113" rx="3" ry="3" width="102" height="7" />
              <rect x="402" y="91" rx="3" ry="3" width="178" height="6" />
              <rect x="405" y="139" rx="3" ry="3" width="178" height="6" />
              <rect x="416" y="162" rx="3" ry="3" width="102" height="7" />
              <rect x="405" y="189" rx="3" ry="3" width="178" height="6" />
              <rect x="5" y="8" rx="3" ry="3" width="669" height="7" />
              <rect x="406" y="223" rx="14" ry="14" width="72" height="32" />
              <rect x="505" y="224" rx="14" ry="14" width="72" height="32" />
              <rect x="376" y="41" rx="3" ry="3" width="231" height="29" />
            </ContentLoader>
          </div>
          <div class="loading">
            <ContentLoader viewBox="0 0 700 700" :width="700" :height="350">
              <rect x="4" y="8" rx="3" ry="3" width="7" height="288" />
              <rect x="6" y="289" rx="3" ry="3" width="669" height="8" />
              <rect x="670" y="9" rx="3" ry="3" width="6" height="285" />
              <rect x="55" y="42" rx="16" ry="16" width="274" height="216" />
              <rect x="412" y="113" rx="3" ry="3" width="102" height="7" />
              <rect x="402" y="91" rx="3" ry="3" width="178" height="6" />
              <rect x="405" y="139" rx="3" ry="3" width="178" height="6" />
              <rect x="416" y="162" rx="3" ry="3" width="102" height="7" />
              <rect x="405" y="189" rx="3" ry="3" width="178" height="6" />
              <rect x="5" y="8" rx="3" ry="3" width="669" height="7" />
              <rect x="406" y="223" rx="14" ry="14" width="72" height="32" />
              <rect x="505" y="224" rx="14" ry="14" width="72" height="32" />
              <rect x="376" y="41" rx="3" ry="3" width="231" height="29" />
            </ContentLoader>
          </div>
          <div class="loading">
            <ContentLoader viewBox="0 0 700 700" :width="700" :height="350">
              <rect x="4" y="8" rx="3" ry="3" width="7" height="288" />
              <rect x="6" y="289" rx="3" ry="3" width="669" height="8" />
              <rect x="670" y="9" rx="3" ry="3" width="6" height="285" />
              <rect x="55" y="42" rx="16" ry="16" width="274" height="216" />
              <rect x="412" y="113" rx="3" ry="3" width="102" height="7" />
              <rect x="402" y="91" rx="3" ry="3" width="178" height="6" />
              <rect x="405" y="139" rx="3" ry="3" width="178" height="6" />
              <rect x="416" y="162" rx="3" ry="3" width="102" height="7" />
              <rect x="405" y="189" rx="3" ry="3" width="178" height="6" />
              <rect x="5" y="8" rx="3" ry="3" width="669" height="7" />
              <rect x="406" y="223" rx="14" ry="14" width="72" height="32" />
              <rect x="505" y="224" rx="14" ry="14" width="72" height="32" />
              <rect x="376" y="41" rx="3" ry="3" width="231" height="29" />
            </ContentLoader>
          </div>
        </template>
        <template v-if="!loading">
          <div
            class="Activity__Component__TabsContent__Group"
            v-for="(activity, date) in groupedActivities"
            :key="date"
          >
            <div class="Activity__Component__TabsContent__Group__Date">
              {{ parsedGroupedDate(date) }}
            </div>
            <transition-group name="fade" mode="out-in">
              <ActivityItem
                v-for="item in activity"
                :key="item.Id"
                :item="item"
                :ref="'taskElement' + item.Id"
                @onDelete="onDelete"
                :entityType="entityType"
                :entityId="entityId"
              />
            </transition-group>
          </div>
        </template>
        <div v-if="haveNoActivities" class="no-activities">
          <h1>לא קיימות פעילויות חדשות</h1>
          <h1>כאן יופיעו הפעילויות - משימות עתידיות, והערות.</h1>
          <b-icon size="is-large" icon="timeline-text-outline"> </b-icon>
        </div>
      </div>
      <div v-show="tab === 2">
        <div class="Activity__Component__TabsContent__Tasks">
          <div class="Activity__Component__TabsContent__Tasks__Header">
            <button class="new" @click="newTask">צור משימה</button>
          </div>
          <div class="Activity__Component__TabsContent__Tasks__Content">
            <template v-if="!loading && !haveNoTasks">
              <div
                class="Activity__Component__TabsContent__Group"
                v-for="(activity, date) in groupedActivitiesTasks"
                :key="date"
              >
                <div class="Activity__Component__TabsContent__Group__Date">
                  {{ parsedGroupedDate(date) }}
                </div>
                <transition-group name="fade" mode="out-in">
                  <ActivityItem
                    v-for="item in activity"
                    :key="item.Id"
                    :item="item"
                    @onDelete="onDelete"
                    :entityType="entityType"
                    :entityId="entityId"
                  />
                </transition-group>
              </div>
            </template>
            <div v-if="haveNoTasks">
              <h1>צור משימה מתוזמנת על מנת שתוכל לעקוב, ולעקוב אחר שינויים</h1>
            </div>
          </div>
        </div>
      </div>
      <div v-show="tab === 3">
        <div class="Activity__Component__TabsContent__Tasks__Header">
          <button @click="newNote" class="new">צור הערה</button>
        </div>
        <div class="Activity__Component__TabsContent__Tasks__Content">
          <template v-if="!loading && !haveNoNotes">
            <div
              class="Activity__Component__TabsContent__Group"
              v-for="(activity, date) in groupedActivitiesNotes"
              :key="date"
            >
              <div class="Activity__Component__TabsContent__Group__Date">
                {{ parsedGroupedDate(date) }}
              </div>
              <transition-group name="fade" mode="out-in">
                <ActivityItem
                  v-for="item in activity"
                  :key="item.Id"
                  :item="item"
                  @onDelete="onDelete"
                  :entityType="entityType"
                  :entityId="entityId"
                />
              </transition-group>
            </div>
          </template>
          <div v-if="haveNoNotes">
            <h1>צור הערה על מנת שתוכל לעקוב, ולעקוב אחר שינויים</h1>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ActivityItem from "@/components/ActivityItem.vue";
import moment from "moment";
import { ContentLoader } from "vue-content-loader";
import EntityItemsService from "@/services/EntityItemsService";
import _ from "lodash";
import NewActivity from "./NewActivity.vue";

export default {
  name: "Activity",
  components: {
    ActivityItem,
    ContentLoader,
    NewActivity,
  },
  props: {
    activities: Array,
    loading: Boolean,
    entityType: String,
    entityId: String,
  },
  updated() {
    const params = window.location.search.substring(1);
    const lastIdx = params.lastIndexOf("=");
    const taskId = params.slice(lastIdx + 1);
    const task = this.$refs[`taskElement${taskId}`][0];
    task.isOpen = true;
    this.$nextTick(() => {
      task.$el.scrollIntoView({ behavior: "smooth" });
    });
  },
  computed: {
    haveNoActivities() {
      return (
        !this.loading &&
        (!this.groupedActivities || !Object.keys(this.groupedActivities).length)
      );
    },
    haveNoNotes() {
      return (
        !this.loading &&
        (!this.groupedActivitiesNotes ||
          !Object.keys(this.groupedActivitiesNotes).length)
      );
    },
    haveNoTasks() {
      return (
        !this.loading &&
        (!this.groupedActivitiesTasks ||
          !Object.keys(this.groupedActivitiesTasks).length)
      );
    },
    groupedActivities() {
      if (this.activities && this.activities.length) {
        const withoutDeleted = this.activities.filter(
          (item) => this.deletedItems.indexOf(item.Id) === -1
        );
        return _.groupBy(withoutDeleted, (item) =>
          moment(item.CreateDate, "YYYY-MM-DDThh:mm").format("MM/YYYY")
        );
      }
      return null;
    },
    groupedActivitiesNotes() {
      if (this.activities && this.activities.length) {
        const withoutDeleted = this.activities.filter(
          (item) =>
            this.deletedItems.indexOf(item.Id) === -1 && item.ItemType === 1
        );
        return _.groupBy(withoutDeleted, (item) =>
          moment(item.CreateDate, "YYYY-MM-DDThh:mm").format("MM/YYYY")
        );
      }
      return null;
    },
    groupedActivitiesTasks() {
      if (this.activities && this.activities.length) {
        const withoutDeleted = this.activities.filter(
          (item) =>
            this.deletedItems.indexOf(item.Id) === -1 && item.ItemType === 2
        );
        return _.groupBy(withoutDeleted, (item) =>
          moment(item.CreateDate, "YYYY-MM-DDThh:mm").format("MM/YYYY")
        );
      }
      return null;
    },
  },
  data() {
    return {
      tab: 1,
      showNewNote: false,
      showNewTask: false,
      deletedItems: [],
    };
  },
  methods: {
    onDelete(id) {
      this.deletedItems.push(id);
    },
    saveTask(data) {
      this.$refs.taskActivity.setLoading(true);
      EntityItemsService.addTask(this.entityType, this.entityId, data)
        .then((r) => {
          this.activities.unshift(r.data);
          this.showNewTask = false;
        })
        .catch(() => {
          this.$refs.taskActivity.setLoading(false);
        })
        .finally(() => {});
    },
    saveNote(data) {
      this.$refs.noteActivity.setLoading(true);
      EntityItemsService.addNote(this.entityType, this.entityId, {
        Body: data.Body,
      })
        .then((r) => {
          this.activities.unshift(r.data);
          this.showNewNote = false;
        })
        .finally(() => {
          this.$refs.noteActivity.setLoading(false);
        });
    },
    close() {
      this.showNewNote = false;
    },
    closeTask() {
      this.showNewTask = false;
    },
    newNote() {
      this.showNewNote = true;
    },
    newTask() {
      this.showNewTask = true;
    },
    loadTasks() {
      this.tab = 2;
    },
    parsedGroupedDate(date) {
      if (date) {
        const localLocale = moment(date, "MM/YYYY");
        localLocale.locale("he");
        return localLocale.format("MMMM YYYY");
      }
      return null;
    },
  },
};
</script>

<style scoped lang="scss">
.Activity__Component__Tabs {
  display: flex;
  border-bottom: 1px solid #cbd6e2;
  margin: 0px 20px;
  position: sticky;
  top: 0px;
  z-index: 2;
  background-color: #eef1f3;
  padding-top: 6px;

  .Activity__Component__Tabs__Item {
    padding: 15px 25px;
    position: relative;
    cursor: pointer;
    font-size: 15px;

    &:not(.active):hover {
      color: #4188b7;
    }

    &.active:after {
      content: "";
      width: 100%;
      position: absolute;
      z-index: 2;
      bottom: -3px;
      background-color: #2e3f50;
      height: 5px;
      right: 0;
      border-radius: 3px;
    }
  }
}
.Activity__Component__TabsContent {
  padding: 20px 30px;

  & .Activity__Item {
    margin: 8px 0;
  }
}
.loading {
  max-height: 300px;
  width: 600px;
  margin: 0 auto;
}
.no-activities {
  font-size: 25px;
  text-align: center;
  opacity: 0.8;

  span {
    margin-top: 50px;
    ::v-deep .mdi-timeline-text-outline {
      &::before {
        font-size: 50px;
      }
    }
  }
}
button.new {
  background-color: #3b5671;
  color: white;
  border: none;
  border-radius: 3px;
  padding: 8px 18px;
  cursor: pointer;

  &:active {
    transform: scale(0.98);
  }
  &:hover {
    background-color: #4d6782;
  }
}
.Activity__Component__TabsContent__Tasks__Header {
  display: flex;
  justify-content: flex-end;
}
.Activity__Component__TabsContent__Tasks__Content {
  h1 {
    text-align: center;
  }
}
</style>
