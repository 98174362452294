var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.ready
    ? _c(
        "div",
        { staticClass: "ReportAlertWidget__Component" },
        [
          _vm._l(_vm.Alerts, function(item) {
            return _c("label", { key: item.Id }, [
              _vm._v(" " + _vm._s(item.Name) + " ")
            ])
          }),
          !_vm.Alerts.length ? _c("div", [_vm._v(" אין התראות ")]) : _vm._e()
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }