var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "fade" } }, [
    _vm.ready && _vm.results != null
      ? _c("div", { staticClass: "VehicleReportsWidget__Component" }, [
          _vm.results == 0 ? _c("div", [_vm._v(" אין רכבים ")]) : _vm._e(),
          _vm.results > 0
            ? _c(
                "div",
                [
                  _vm._l(_vm.items, function(item) {
                    return _c(
                      "div",
                      {
                        key: item.Id,
                        staticClass: "VehicleReportsWidget__Component__Item"
                      },
                      [
                        _c("span", [_vm._v(_vm._s(item.PlateNumber))]),
                        _c("span", [_vm._v(_vm._s(item.VehicleType.Name))])
                      ]
                    )
                  }),
                  _c("button", { on: { click: _vm.showAll } }, [
                    _vm._v("הצג את כל הרכבים")
                  ])
                ],
                2
              )
            : _vm._e()
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }