var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "fade" } }, [
    _vm.ready && _vm.OwnerContracts.length && _vm.OwnerContracts
      ? _c(
          "div",
          { staticClass: "VehicleContractsWidget__Component" },
          _vm._l(_vm.OwnerContracts, function(item) {
            return _c(
              "div",
              {
                key: item.Id,
                staticClass: "VehicleContractsWidget__Component__Item"
              },
              [
                _c("label", [
                  _vm._v(" בעלים: "),
                  _c("span", [_vm._v(_vm._s(item.ContractOwner.Name))])
                ]),
                _c("label", [
                  _vm._v(" שוכר: "),
                  _c("a", { attrs: { href: "/clients/" + item.Profile.Id } }, [
                    _c("span", [_vm._v(_vm._s(item.Profile.Name))])
                  ])
                ]),
                _c("label", [
                  _vm._v(" ח.פ: "),
                  _c("span", [_vm._v(_vm._s(item.Profile.IdNumber))])
                ]),
                _c(
                  "label",
                  [
                    _vm._v(" מתאריך: "),
                    _c("FieldDateHourInline", {
                      attrs: { value: item.StartDate }
                    })
                  ],
                  1
                ),
                _c(
                  "label",
                  [
                    _vm._v(" עד תאריך: "),
                    _c("FieldDateHourInline", {
                      attrs: { value: item.EndDate }
                    })
                  ],
                  1
                ),
                _c(
                  "label",
                  [
                    _vm._v(" תאריך עדכון: "),
                    _c("FieldDateHourInline", {
                      attrs: { value: item.UpdateDate }
                    })
                  ],
                  1
                ),
                _c(
                  "router-link",
                  {
                    attrs: {
                      to: { name: "VehicleContract", params: { id: item.Id } }
                    }
                  },
                  [_c("span", { staticClass: "preview" }, [_vm._v("הצג")])]
                )
              ],
              1
            )
          }),
          0
        )
      : _c("div", [_vm._v("לא קיים חוזי בעלות")])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }