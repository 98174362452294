<template>
  <div>
    <b-button :loading="loading" @click="open">הצג טבלה</b-button>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import ArchiveService from '@/services/ArchiveService';
import DialogReportHistory from '@/components/DialogReportHistory.vue';

export default {
    name: 'ReportPhotosWidget',
    props: ['ready'],
    computed: {
        ...mapState('report', ['Id']),
    },
    data() {
        return {
            loading: false,
            data: [],
        };
    },
    methods: {
        init() {
            this.$emit('onReady', true);
        },
        open() {
            this.loading = true;
                ArchiveService.getHistory(this.Id)
                .then((r) => {
                    this.data = r.data;

                    this.$buefy.modal.open({
                        component: DialogReportHistory,
                        props: {
                            data: this.data,
                        },
                        canCancel: false,
                        hasModalCard: true,
                        trapFocus: true,
                    });
                })
                .finally(() => {
                    this.loading = false;
                });
        },
    },
    watch: {
        ready(newValue) {
            if (newValue) {
                this.init();
            }
        },
    },
};
</script>

<style lang="scss">
.image {
    width: 100px;
    height: 100px;
    margin: 5px;

    img {
        max-width: 100%;
        display: block;
        margin: 0 auto;
    }
}

</style>
