<template>
  <div class="Activity__Item">
    <div class="Activity__Item__Box">
      <div class="Activity__Item__Box__Content">
        <div @click="toggleOpen" class="Activity__Item__Box__Content__Slot">
          <div class="Activity__Item__Box__Content__Actions" @click.stop>
            <span class="Activity__Item__Box__Content__Date">{{
              parsedDate
            }}</span>
            <b-dropdown
              aria-role="list"
              v-if="isOpen && item.ItemType !== 3"
              animation=""
            >
              <template #trigger="{ active }">
                <b-button
                  label="פעולות"
                  type="is-primary"
                  v-if="isAdmin"
                  :icon-right="active ? 'menu-up' : 'menu-down'"
                />
              </template>

              <b-dropdown-item @click="deleteItem" aria-role="listitem"
                >מחיקה</b-dropdown-item
              >
            </b-dropdown>
          </div>
          <div class="Activity__Item__Box__Content__Text">
            <div
              v-if="item.ItemType !== 3"
              class="Activity__Item__Box__Content__Text__Icon"
              :class="{ open: isOpen }"
            >
              <b-icon icon="chevron-up" />
            </div>
            <strong>{{ getType(item.ItemType) }} </strong>
            <span v-if="item.ItemType !== 3"> ע"י </span>
            <span v-if="item.ItemType !== 3" class="user">{{
              item.CreatorName
            }}</span>
            <div
              class="Activity__Item__Box__Content__Text__Comments"
              v-if="item.ItemType !== 3 && item.Comments && item.Comments.length && !isOpen"
            >
              <b-icon icon="comment-processing" size="is-small" />
              <span>{{ item.Comments && item.Comments.length }}</span>
            </div>
            <div v-if="item.ItemType === 3">
              סטטוס שונה ל {{ item.Title }} - {{ item.Body }}
            </div>
          </div>
        </div>
        <div
          v-if="item.ItemType !== 3"
          class="Activity__Item__Box__Content__Component"
        >
          <component
            :is="componentType"
            :entityType="entityType"
            :entityId="entityId"
            :item="item"
            :isOpen="isOpen"
          />
        </div>
      </div>
      <div class="Activity__Item__Box__Comments" v-if="isOpen">
        <ActivityComments
          :comments="item.Comments"
          :entityType="entityType"
          :entityId="entityId"
          :id="item.Id"
        />
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import EntityItemsService from "@/services/EntityItemsService";
import ActivityItemNote from "@/components/ActivityItemNote.vue";
import ActivityItemTask from "@/components/ActivityItemTask.vue";
import ActivityComments from "./ActivityComments.vue";

export default {
  name: "ActivityItem",
  props: {
    item: Object,
    entityType: String,
    entityId: String,
  },
  components: {
    ActivityComments,
    ActivityItemTask,
  },
  computed: {
    parsedDate() {
      if (this.item) {
        const localLocale = moment(this.item.CreateDate);
        localLocale.locale("he");
        return `${localLocale.format(
          "MMM DD, YYYY"
        )} בשעה  ${localLocale.format("HH:mm")}`;
      }
      return null;
    },
    isAdmin() {
      return this.$store.state.auth?.user?.claims?.isAdmin === "True";
    },
    componentType() {
      if (this.item.ItemType) {
        switch (this.item.ItemType) {
          case 1:
            return ActivityItemNote;
          case 2:
            return ActivityItemTask;
          default:
            return null;
        }
      }
      return null;
    },
  },
  data() {
    return {
      isOpen: false,
      editMode: false,
      isLoading: false,
    };
  },
  methods: {
    update() {
      this.isLoading = true;
      const body = this.$refs.editor.getHTML();
      const updateMethod = this.getTypeUpdateService(this.item.ItemType);
      updateMethod(this.entityType, this.entityId, this.item.Id, { Body: body })
        .then(() => {
          this.$buefy.toast.open({
            type: "is-success",
            message: `${this.getType(this.item.ItemType)} נשמרה!`,
            duration: 4000,
          });
          this.item.Body = body;
          this.editMode = false;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    edit() {
      if (this.isOpen) {
        this.editMode = true;
      }
    },
    toggleOpen(event, forceOpen) {
      if (this.editMode) return;
      if (forceOpen) {
        this.isOpen = true;
      } else {
        this.isOpen = !this.isOpen;
      }
    },
    deleteItem() {
      this.$buefy.dialog.confirm({
        title: `מחיקת ${this.getType(this.item.ItemType)}`,
        message: `האם למחוק את ה${this.getType(
          this.item.ItemType
        )}? שימו לב כי לאחר המחיקה לא יהיה ניתן לבטל את הפעולה!`,
        confirmText: `מחק ${this.getType(this.item.ItemType)}`,
        cancelText: "ביטול",
        type: "is-danger",
        closeOnConfirm: false,
        hasIcon: true,
        onConfirm: (a, instance) => {
          const deleteMethod = this.getTypeDeleteService(this.item.ItemType);
          deleteMethod(this.entityType, this.entityId, this.item.Id).then(
            () => {
              this.$emit("onDelete", this.item.Id);
              this.$buefy.toast.open({
                type: "is-danger",
                message: `${this.getType(this.item.ItemType)} נמחקה!`,
                duration: 8000,
              });
              instance.close();
            }
          );
        },
      });
    },
    getType(id) {
      switch (id) {
        case 1:
          return "הערה";
        case 2:
          return "משימה";
        default:
          return null;
      }
    },
    getTypeDeleteService(id) {
      switch (id) {
        case 1:
          return EntityItemsService.deleteNote;
        case 2:
          return EntityItemsService.deleteTask;
        default:
          return null;
      }
    },
    getTypeUpdateService(id) {
      switch (id) {
        case 1:
          return EntityItemsService.editNote;
        case 2:
          return EntityItemsService.editTask;
        default:
          return null;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.Activity__Item {
  display: flex;
  flex-direction: column;
  align-items: baseline;

  .Activity__Item__Box__Comments {
    width: 100%;
  }

  .Activity__Item__Box {
    width: 100%;
    box-shadow: 0px 0px 5px #c0c0c03d;
    background: white;
    display: flex;
    padding: 10px 20px;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 3px;

    .Activity__Item__Box__Icon {
      width: 30px;
    }
    .Activity__Item__Box__Content {
      width: 100%;

      ::v-deep .NewTask__Component__Editor_Inner {
        padding: 0px;
        border: 2px solid #2fc9e8;
        border-radius: 3px;
        margin-bottom: 10px;

        .ProseMirror {
          min-height: 100px;
        }
      }
    }
    .Activity__Item__Box__Content__Component {
      padding-right: 15px;
      font-size: 15px;
    }
    .Activity__Item__Box__Content__Text__Editor__Actions {
      margin-bottom: 20px;

      button {
        border: none;
        background-color: transparent;
        padding: 5px 10px;
        cursor: pointer;
        line-height: normal;
        height: unset;
        font-size: 15px;

        &:active {
          transform: scale(0.97);
        }

        &.Save {
          background-color: #486480;
          color: white;
          border-radius: 3px;
          transition: transform 0.1s cubic-bezier(0.075, 0.82, 0.165, 1);
          &:hover {
            background-color: #597794;
          }
        }
        &.Cancel {
          background-color: #d0d0d0;
          color: black;
          border-radius: 3px;
          margin-right: 5px;

          &:hover {
            background-color: #e4e4e4;
          }
        }
      }
    }
    .Activity__Item__Box__Content__Text__Editor {
      direction: rtl;
      padding-right: 14px;
      font-size: 14px;
      position: relative;
      color: black;
      padding: 10px 10px 30px 10px;
      border: 1px solid transparent;
      border-radius: 3px;
      max-height: 55px;
      overflow: hidden;

      &:not(.open)::after {
        background-image: linear-gradient(#04040400 50%, #ffffff);
        height: 55px;
        opacity: 1;
        pointer-events: none;
        position: absolute;
        top: 0px;
        left: 0px;
        right: 0px;
        content: "";
      }
      &.open {
        max-height: unset;
        &:hover {
          background-color: #e5f5f8;
          border: 1px solid #addbff;
        }
      }
    }
    .Activity__Item__Box__Content .Activity__Item__Box__Content__Slot {
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;

      .Activity__Item__Box__Content__Date {
        color: #516f90;
        font-size: 14px;
        margin: 10px;
        float: left;
      }
      .Activity__Item__Box__Content__Actions {
        display: flex;
        flex-direction: inherit;
        align-items: center;

        .button.is-primary {
          background-color: transparent;
          color: #0091ae;
          border: none;

          &:hover {
            text-decoration: underline;
          }
          &:active,
          &:focus {
            outline: none;
            border: none;
            box-shadow: none;
            color: #7fd1de;
          }

          ::v-deep .icon:last-child:not(:first-child) {
            margin-right: -0.1em;
          }
        }
      }

      .Activity__Item__Box__Content__Text {
        display: flex;
        align-items: center;
        font-size: 14px;

        .Activity__Item__Box__Content__Text__Comments {
          display: flex;
          align-items: center;
          justify-content: center;
          color: #4b96a5;
          margin-inline-start: 10px;
          font-size: 14px;
          line-height: 20px;
          margin-top: 2px;

          span:last-of-type {
            font-size: 14px;
            margin-right: 3px;
            margin-top: -2px;
          }
        }

        strong {
          margin-inline-end: 5px;
          margin-inline-start: 5px;
        }

        .Activity__Item__Box__Content__Text__Icon {
          transform: rotate(-90deg);
          color: #0091ae;
          margin-inline-start: -10px;
          cursor: pointer;
          transition: transform 0.1s linear;

          &.open {
            transform: rotate(-180deg) !important;
          }

          ::v-deep .mdi::before {
            font-size: 19px;
          }
        }

        .user {
          // color: #5FC4E8;
          margin-inline-start: 5px;
        }
        .action {
          color: #5fc4e8;
        }
      }
    }
  }
}
</style>
