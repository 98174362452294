<template>
    <transition name="fade">
        <div v-if="ready && contract" class="DriverContractForVehicleWidget__Component">
            <div class="DriverContractForVehicleWidget__Component__Item" v-if="contract.length">
                <div v-for="item in contract" :key="item.Id">
                    <label>
                        נהג:
                        <router-link :to="{ name: 'Driver', params: { id: item.Driver.Id } }">
                           {{ item.Driver.Name }}
                        </router-link>
                    </label>
                    <label>
                        רכב:
                       <span>{{ item.Vehicle.Name }} </span>
                    </label>
                    <label>
                        מתאריך:
                        <FieldDateHourInline :value="item.StartDate" />
                    </label>
                    <label>
                        עד תאריך:
                        <FieldDateHourInline :value="item.EndDate" />
                    </label>
                    <!-- <router-link :to="{ name: 'DriverContract', params: { id: contract.Id }}">
                    <span class="preview">הצג</span>
                </router-link> -->
                </div>
            </div>
            <div v-else>לא קיים חוזי נהג</div>
        </div>
    </transition>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import VehicleService from '@/services/VehicleService';
import FieldDateHourInline from '../Fields/FieldDateHourInline.vue';

export default {
    name: 'DriverContractForVehicleWidget',
    props: ['ready', 'namespace', 'delegate'],
    data() {
        return {
            contract: null,
        };
    },
    beforeCreate() {
        const { namespace } = this.$options.propsData;
        const { mapState } = createNamespacedHelpers(namespace);
        this.$options.computed = {
            ...mapState(['Id']),
        };
    },
    methods: {
        init() {
            if (this.Id) {
                if (this.delegate) {
                    VehicleService.getAdminDriverContracts(this.Id)
                        .then((r) => {
                            this.contract = r.data;
                        })
                        .finally(() => {
                            this.$emit('onReady', { titleCount: this.contract?.length });
                        });
                } else {
                    VehicleService.getDriverContracts(this.Id)
                        .then((r) => {
                            this.contract = r.data;
                        })
                        .finally(() => {
                            this.$emit('onReady', { titleCount: this.contract?.length });
                        });
                }
            }
        },
    },
    watch: {
        ready(newValue) {
            if (newValue) {
                this.init();
            }
        },
    },
    components: {
        FieldDateHourInline,
    },
};
</script>

<style lang="scss" scoped>
.DriverContractForVehicleWidget__Component {
    // background-color: #eaf0f6;
    width: 100%;

    .DriverContractForVehicleWidget__Component__Item {
        border: 1px solid #e4eaf1;
        background-color: #eef1f3;
        border-radius: 3px;
        margin: 10px 0;
        padding: 10px;
        position: relative;
        display: flex;
        flex-direction: column;
        font-weight: bold;

        span {
            font-weight: normal;
        }

        a {
            color: #06c;
            cursor: pointer;

            &:hover {
                text-decoration: underline;
            }
        }

        div {
            display: flex;
            flex-direction: column;
        }
    }

    .preview {
        position: absolute;
        left: 10px;
        top: 10px;
        color: #57B7CA;
        cursor: pointer;
        font-weight: 500 !important;

        &:hover {
            text-decoration: underline;
        }
    }
}
</style>
