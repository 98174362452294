<template>
    <transition name="fade">
        <div v-if="ready && results!=null" class="VehicleReportsWidget__Component">
            <div v-if="results == 0">
                {{widget.noData || 'אין נסיעות'}}
            </div>
            <div v-if="results > 0">
                <div v-for="item in items" :key="item.Id"
                    class="VehicleReportsWidget__Component__Item">
                    <span>{{item.ReportNumber}}</span>
                    <span>{{item.ProfileName}}</span>
                    <span>{{item.Municipality}}</span>
                </div>
                <button>הצג את כל הדוחות</button>
            </div>
        </div>
    </transition>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import TollRoadReportsService from '@/services/TollRoadReportsService';

export default {
    name: 'VehicleReportsWidget',
    props: ['ready', 'namespace', 'query', 'widget'],
    beforeCreate() {
        const { namespace } = this.$options.propsData;
        const { mapState } = createNamespacedHelpers(namespace);
        this.$options.computed = {
            ...mapState(['Id']),
        };
    },
    data() {
        return {
            results: null,
            items: [],
        };
    },
    methods: {
        init() {
            if (this.query) {
                TollRoadReportsService.search({ PageSize: 3, ...this.query(this.Id) })
                .then((r) => {
                    this.results = r.data.TotalResults;
                    this.items = r.data.Items;
                })
                .finally(() => {
                    this.$emit('onReady', { titleCount: this.results });
                });
            }
        },
    },
    watch: {
        ready(newValue) {
            if (newValue) {
                this.init();
            }
        },
    },
};
</script>

<style lang="scss" scoped>
    .VehicleReportsWidget__Component {
        // background-color: #eaf0f6;
        width: 100%;

        .VehicleReportsWidget__Component__Item {
            border-radius: 3px;
            margin: 10px 0;
            padding: 10px;
            border: 1px solid #e4eaf1;
            position: relative;
            display: flex;
            flex-direction: row;
            font-weight: bold;
            background-color: #eef1f3;
            justify-content: space-around;

            span {
                font-weight: normal;
            }
            a {
                color: #06c;
                cursor: pointer;
                &:hover {
                    text-decoration: underline;
                }
            }
        }
        button {
            border: none;
            background-color: transparent;
            text-align: center;
            width: 100%;
            color: #06c;
            cursor: pointer;

            &:hover {
                text-decoration: underline;
            }
        }
    }
</style>
