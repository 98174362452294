var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "fade" } }, [
    !_vm.isInitiating
      ? _c("div", { staticClass: "AccidentPdfFileWidget__Component" }, [
          _vm.ready
            ? _c("div", [
                _vm.hasDocument
                  ? _c("a", { attrs: { href: _vm.PdfUrl, target: "_blank" } }, [
                      _vm._v("הצג מסמך")
                    ])
                  : _vm._e()
              ])
            : _vm._e()
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }