<template>
    <transition name="fade">
        <div v-if="ready" class="VehicleContractForReportWidget__Component">
            <div v-if="contract" class="VehicleContractForReportWidget__Component__Item">
                <label>
                    שוכר:
                    <a :href="`/clients/${contract.Profile.Id}`"><span>{{contract.Profile.Name}}</span></a>
                </label>
                <label>
                    ח.פ:
                    <span>{{contract.Profile.IdNumber}}</span>
                </label>
                <label>
                    מתאריך:
                    <FieldDateHourInline :value="contract.StartDate" />
                </label>
                <label>
                    עד תאריך:
                    <FieldDateHourInline :value="contract.EndDate" />
                </label>
                <label>
                   הצהרת חוכר:
                    <span>{{contract.HasTransferHtml ? 'כן' : 'לא'}}</span>
                </label>
                <label>
                    קיים טופס איסוף השכרה:
                    <span>{{contract.HasRentalPickUpFormHtml ? 'כן' : 'לא'}}</span>
                </label>
                <label>
                    קיים טופס החזרת השכרה:
                    <span>{{contract.HasRentalReturnFormHtml ? 'כן' : 'לא'}}</span>
                </label>
                <label>
                    קיים רישיון נהיגה:
                    <span>{{contract.HasDriversLicenseHtml ? 'כן' : 'לא'}}</span>
                </label>
                <label>
                    תאריך עדכון:
                    <FieldDateHourInline :value="contract.UpdateDate" />
                </label>
                <label>
                        עודכן ע"י:
                    <span> {{ contract.UpdatingUserName ? contract.UpdatingUserName : '-' }} </span>
                </label>
                <router-link :to="{ name: 'VehicleContract', params: { id: contract.Id }}">
                    <span class="preview">הצג</span>
                </router-link>
            </div>
            <div v-if="!contract">לא קיים חוזה רכב</div>
        </div>
    </transition>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import ReportService from '@/services/ReportService';
import FieldDateHourInline from '../Fields/FieldDateHourInline.vue';

export default {
    name: 'VehicleContractForReportWidget',
    props: ['ready', 'namespace'],
    data() {
        return {
            contract: null,
        };
    },
    beforeCreate() {
        const { namespace } = this.$options.propsData;
        const { mapState } = createNamespacedHelpers(namespace);
        this.$options.computed = {
            ...mapState(['Id']),
        };
    },
    methods: {
        init() {
            if (this.Id) {
                ReportService.getVehicleContract(this.Id)
                .then((r) => {
                    this.contract = r.data;
                });
            }
            this.$emit('onReady', true);
        },
    },
    watch: {
        ready(newValue) {
            if (newValue) {
                this.init();
            }
        },
    },
    components: {
        FieldDateHourInline,
    },
};
</script>

<style lang="scss" scoped>
    .VehicleContractForReportWidget__Component {
        width: 100%;

        .VehicleContractForReportWidget__Component__Item {
            border: 1px solid #e4eaf1;
            background-color: #eef1f3;
            border-radius: 3px;
            margin: 10px 0;
            padding: 10px;
            position: relative;
            display: flex;
            flex-direction: column;
            font-weight: bold;

            span {
                font-weight: normal;
            }
            a {
                color: #06c;
                cursor: pointer;
                &:hover {
                    text-decoration: underline;
                }
            }
        }
        .preview {
            position: absolute;
            left: 10px;
            top: 10px;
            color: #57B7CA;
            cursor: pointer;
            font-weight: 500 !important;

            &:hover {
                text-decoration: underline;
            }
        }
    }
</style>
