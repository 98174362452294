var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "Activity__Component" }, [
    _c("div", { staticClass: "Activity__Component__Tabs" }, [
      _c(
        "div",
        {
          staticClass: "Activity__Component__Tabs__Item",
          class: { active: _vm.tab === 1 },
          on: {
            click: function($event) {
              _vm.tab = 1
            }
          }
        },
        [_vm._v(" פעילויות ")]
      ),
      _c(
        "div",
        {
          staticClass: "Activity__Component__Tabs__Item",
          class: { active: _vm.tab === 2 },
          on: {
            click: function($event) {
              return _vm.loadTasks()
            }
          }
        },
        [_vm._v(" משימות ")]
      ),
      _c(
        "div",
        {
          staticClass: "Activity__Component__Tabs__Item",
          class: { active: _vm.tab === 3 },
          on: {
            click: function($event) {
              _vm.tab = 3
            }
          }
        },
        [_vm._v(" הערות ")]
      )
    ]),
    _c(
      "div",
      { staticClass: "Activity__Component__TabsContent" },
      [
        _vm.showNewNote
          ? _c("new-activity", {
              ref: "noteActivity",
              attrs: { type: "Note" },
              on: { onClose: _vm.close, onSave: _vm.saveNote }
            })
          : _vm._e(),
        _vm.showNewTask
          ? _c("new-activity", {
              ref: "taskActivity",
              attrs: { type: "Task", "show-title": "true" },
              on: { onClose: _vm.closeTask, onSave: _vm.saveTask }
            })
          : _vm._e(),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.tab === 1,
                expression: "tab === 1"
              }
            ]
          },
          [
            _vm.loading
              ? [
                  _c(
                    "div",
                    { staticClass: "loading" },
                    [
                      _c(
                        "ContentLoader",
                        {
                          attrs: {
                            viewBox: "0 0 700 700",
                            width: "700",
                            height: "350"
                          }
                        },
                        [
                          _c("rect", {
                            attrs: {
                              x: "4",
                              y: "8",
                              rx: "3",
                              ry: "3",
                              width: "7",
                              height: "288"
                            }
                          }),
                          _c("rect", {
                            attrs: {
                              x: "6",
                              y: "289",
                              rx: "3",
                              ry: "3",
                              width: "669",
                              height: "8"
                            }
                          }),
                          _c("rect", {
                            attrs: {
                              x: "670",
                              y: "9",
                              rx: "3",
                              ry: "3",
                              width: "6",
                              height: "285"
                            }
                          }),
                          _c("rect", {
                            attrs: {
                              x: "55",
                              y: "42",
                              rx: "16",
                              ry: "16",
                              width: "274",
                              height: "216"
                            }
                          }),
                          _c("rect", {
                            attrs: {
                              x: "412",
                              y: "113",
                              rx: "3",
                              ry: "3",
                              width: "102",
                              height: "7"
                            }
                          }),
                          _c("rect", {
                            attrs: {
                              x: "402",
                              y: "91",
                              rx: "3",
                              ry: "3",
                              width: "178",
                              height: "6"
                            }
                          }),
                          _c("rect", {
                            attrs: {
                              x: "405",
                              y: "139",
                              rx: "3",
                              ry: "3",
                              width: "178",
                              height: "6"
                            }
                          }),
                          _c("rect", {
                            attrs: {
                              x: "416",
                              y: "162",
                              rx: "3",
                              ry: "3",
                              width: "102",
                              height: "7"
                            }
                          }),
                          _c("rect", {
                            attrs: {
                              x: "405",
                              y: "189",
                              rx: "3",
                              ry: "3",
                              width: "178",
                              height: "6"
                            }
                          }),
                          _c("rect", {
                            attrs: {
                              x: "5",
                              y: "8",
                              rx: "3",
                              ry: "3",
                              width: "669",
                              height: "7"
                            }
                          }),
                          _c("rect", {
                            attrs: {
                              x: "406",
                              y: "223",
                              rx: "14",
                              ry: "14",
                              width: "72",
                              height: "32"
                            }
                          }),
                          _c("rect", {
                            attrs: {
                              x: "505",
                              y: "224",
                              rx: "14",
                              ry: "14",
                              width: "72",
                              height: "32"
                            }
                          }),
                          _c("rect", {
                            attrs: {
                              x: "376",
                              y: "41",
                              rx: "3",
                              ry: "3",
                              width: "231",
                              height: "29"
                            }
                          })
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "loading" },
                    [
                      _c(
                        "ContentLoader",
                        {
                          attrs: {
                            viewBox: "0 0 700 700",
                            width: 700,
                            height: 350
                          }
                        },
                        [
                          _c("rect", {
                            attrs: {
                              x: "4",
                              y: "8",
                              rx: "3",
                              ry: "3",
                              width: "7",
                              height: "288"
                            }
                          }),
                          _c("rect", {
                            attrs: {
                              x: "6",
                              y: "289",
                              rx: "3",
                              ry: "3",
                              width: "669",
                              height: "8"
                            }
                          }),
                          _c("rect", {
                            attrs: {
                              x: "670",
                              y: "9",
                              rx: "3",
                              ry: "3",
                              width: "6",
                              height: "285"
                            }
                          }),
                          _c("rect", {
                            attrs: {
                              x: "55",
                              y: "42",
                              rx: "16",
                              ry: "16",
                              width: "274",
                              height: "216"
                            }
                          }),
                          _c("rect", {
                            attrs: {
                              x: "412",
                              y: "113",
                              rx: "3",
                              ry: "3",
                              width: "102",
                              height: "7"
                            }
                          }),
                          _c("rect", {
                            attrs: {
                              x: "402",
                              y: "91",
                              rx: "3",
                              ry: "3",
                              width: "178",
                              height: "6"
                            }
                          }),
                          _c("rect", {
                            attrs: {
                              x: "405",
                              y: "139",
                              rx: "3",
                              ry: "3",
                              width: "178",
                              height: "6"
                            }
                          }),
                          _c("rect", {
                            attrs: {
                              x: "416",
                              y: "162",
                              rx: "3",
                              ry: "3",
                              width: "102",
                              height: "7"
                            }
                          }),
                          _c("rect", {
                            attrs: {
                              x: "405",
                              y: "189",
                              rx: "3",
                              ry: "3",
                              width: "178",
                              height: "6"
                            }
                          }),
                          _c("rect", {
                            attrs: {
                              x: "5",
                              y: "8",
                              rx: "3",
                              ry: "3",
                              width: "669",
                              height: "7"
                            }
                          }),
                          _c("rect", {
                            attrs: {
                              x: "406",
                              y: "223",
                              rx: "14",
                              ry: "14",
                              width: "72",
                              height: "32"
                            }
                          }),
                          _c("rect", {
                            attrs: {
                              x: "505",
                              y: "224",
                              rx: "14",
                              ry: "14",
                              width: "72",
                              height: "32"
                            }
                          }),
                          _c("rect", {
                            attrs: {
                              x: "376",
                              y: "41",
                              rx: "3",
                              ry: "3",
                              width: "231",
                              height: "29"
                            }
                          })
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "loading" },
                    [
                      _c(
                        "ContentLoader",
                        {
                          attrs: {
                            viewBox: "0 0 700 700",
                            width: 700,
                            height: 350
                          }
                        },
                        [
                          _c("rect", {
                            attrs: {
                              x: "4",
                              y: "8",
                              rx: "3",
                              ry: "3",
                              width: "7",
                              height: "288"
                            }
                          }),
                          _c("rect", {
                            attrs: {
                              x: "6",
                              y: "289",
                              rx: "3",
                              ry: "3",
                              width: "669",
                              height: "8"
                            }
                          }),
                          _c("rect", {
                            attrs: {
                              x: "670",
                              y: "9",
                              rx: "3",
                              ry: "3",
                              width: "6",
                              height: "285"
                            }
                          }),
                          _c("rect", {
                            attrs: {
                              x: "55",
                              y: "42",
                              rx: "16",
                              ry: "16",
                              width: "274",
                              height: "216"
                            }
                          }),
                          _c("rect", {
                            attrs: {
                              x: "412",
                              y: "113",
                              rx: "3",
                              ry: "3",
                              width: "102",
                              height: "7"
                            }
                          }),
                          _c("rect", {
                            attrs: {
                              x: "402",
                              y: "91",
                              rx: "3",
                              ry: "3",
                              width: "178",
                              height: "6"
                            }
                          }),
                          _c("rect", {
                            attrs: {
                              x: "405",
                              y: "139",
                              rx: "3",
                              ry: "3",
                              width: "178",
                              height: "6"
                            }
                          }),
                          _c("rect", {
                            attrs: {
                              x: "416",
                              y: "162",
                              rx: "3",
                              ry: "3",
                              width: "102",
                              height: "7"
                            }
                          }),
                          _c("rect", {
                            attrs: {
                              x: "405",
                              y: "189",
                              rx: "3",
                              ry: "3",
                              width: "178",
                              height: "6"
                            }
                          }),
                          _c("rect", {
                            attrs: {
                              x: "5",
                              y: "8",
                              rx: "3",
                              ry: "3",
                              width: "669",
                              height: "7"
                            }
                          }),
                          _c("rect", {
                            attrs: {
                              x: "406",
                              y: "223",
                              rx: "14",
                              ry: "14",
                              width: "72",
                              height: "32"
                            }
                          }),
                          _c("rect", {
                            attrs: {
                              x: "505",
                              y: "224",
                              rx: "14",
                              ry: "14",
                              width: "72",
                              height: "32"
                            }
                          }),
                          _c("rect", {
                            attrs: {
                              x: "376",
                              y: "41",
                              rx: "3",
                              ry: "3",
                              width: "231",
                              height: "29"
                            }
                          })
                        ]
                      )
                    ],
                    1
                  )
                ]
              : _vm._e(),
            !_vm.loading
              ? _vm._l(_vm.groupedActivities, function(activity, date) {
                  return _c(
                    "div",
                    {
                      key: date,
                      staticClass: "Activity__Component__TabsContent__Group"
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "Activity__Component__TabsContent__Group__Date"
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.parsedGroupedDate(date)) + " "
                          )
                        ]
                      ),
                      _c(
                        "transition-group",
                        { attrs: { name: "fade", mode: "out-in" } },
                        _vm._l(activity, function(item) {
                          return _c("ActivityItem", {
                            key: item.Id,
                            ref: "taskElement" + item.Id,
                            refInFor: true,
                            attrs: {
                              item: item,
                              entityType: _vm.entityType,
                              entityId: _vm.entityId
                            },
                            on: { onDelete: _vm.onDelete }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  )
                })
              : _vm._e(),
            _vm.haveNoActivities
              ? _c(
                  "div",
                  { staticClass: "no-activities" },
                  [
                    _c("h1", [_vm._v("לא קיימות פעילויות חדשות")]),
                    _c("h1", [
                      _vm._v("כאן יופיעו הפעילויות - משימות עתידיות, והערות.")
                    ]),
                    _c("b-icon", {
                      attrs: { size: "is-large", icon: "timeline-text-outline" }
                    })
                  ],
                  1
                )
              : _vm._e()
          ],
          2
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.tab === 2,
                expression: "tab === 2"
              }
            ]
          },
          [
            _c(
              "div",
              { staticClass: "Activity__Component__TabsContent__Tasks" },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "Activity__Component__TabsContent__Tasks__Header"
                  },
                  [
                    _c(
                      "button",
                      { staticClass: "new", on: { click: _vm.newTask } },
                      [_vm._v("צור משימה")]
                    )
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "Activity__Component__TabsContent__Tasks__Content"
                  },
                  [
                    !_vm.loading && !_vm.haveNoTasks
                      ? _vm._l(_vm.groupedActivitiesTasks, function(
                          activity,
                          date
                        ) {
                          return _c(
                            "div",
                            {
                              key: date,
                              staticClass:
                                "Activity__Component__TabsContent__Group"
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "Activity__Component__TabsContent__Group__Date"
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.parsedGroupedDate(date)) +
                                      " "
                                  )
                                ]
                              ),
                              _c(
                                "transition-group",
                                { attrs: { name: "fade", mode: "out-in" } },
                                _vm._l(activity, function(item) {
                                  return _c("ActivityItem", {
                                    key: item.Id,
                                    attrs: {
                                      item: item,
                                      entityType: _vm.entityType,
                                      entityId: _vm.entityId
                                    },
                                    on: { onDelete: _vm.onDelete }
                                  })
                                }),
                                1
                              )
                            ],
                            1
                          )
                        })
                      : _vm._e(),
                    _vm.haveNoTasks
                      ? _c("div", [
                          _c("h1", [
                            _vm._v(
                              "צור משימה מתוזמנת על מנת שתוכל לעקוב, ולעקוב אחר שינויים"
                            )
                          ])
                        ])
                      : _vm._e()
                  ],
                  2
                )
              ]
            )
          ]
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.tab === 3,
                expression: "tab === 3"
              }
            ]
          },
          [
            _c(
              "div",
              {
                staticClass: "Activity__Component__TabsContent__Tasks__Header"
              },
              [
                _c(
                  "button",
                  { staticClass: "new", on: { click: _vm.newNote } },
                  [_vm._v("צור הערה")]
                )
              ]
            ),
            _c(
              "div",
              {
                staticClass: "Activity__Component__TabsContent__Tasks__Content"
              },
              [
                !_vm.loading && !_vm.haveNoNotes
                  ? _vm._l(_vm.groupedActivitiesNotes, function(
                      activity,
                      date
                    ) {
                      return _c(
                        "div",
                        {
                          key: date,
                          staticClass: "Activity__Component__TabsContent__Group"
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "Activity__Component__TabsContent__Group__Date"
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.parsedGroupedDate(date)) + " "
                              )
                            ]
                          ),
                          _c(
                            "transition-group",
                            { attrs: { name: "fade", mode: "out-in" } },
                            _vm._l(activity, function(item) {
                              return _c("ActivityItem", {
                                key: item.Id,
                                attrs: {
                                  item: item,
                                  entityType: _vm.entityType,
                                  entityId: _vm.entityId
                                },
                                on: { onDelete: _vm.onDelete }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      )
                    })
                  : _vm._e(),
                _vm.haveNoNotes
                  ? _c("div", [
                      _c("h1", [
                        _vm._v(
                          "צור הערה על מנת שתוכל לעקוב, ולעקוב אחר שינויים"
                        )
                      ])
                    ])
                  : _vm._e()
              ],
              2
            )
          ]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }