<template>
  <transition name="fade">
    <div v-if="ready" class="ReportDetailsWidget__Component">
      <div>
        <div class="field">
          <strong>מספר דוח: </strong>
          <span
            ><router-link :to="`/reports/${reportWidgetModal.Id}`">
              {{ reportWidgetModal.ReportNumber }}</router-link
            ></span
          >
        </div>
        <div class="field">
          <strong>שולם: </strong>
          <span>{{ reportWidgetModal.IsPaid ? "כן" : "לא" }}</span>
        </div>
        <div class="field">
          <strong>סטטוס: </strong>
          <span>{{ reportWidgetModal.Status }}</span>
        </div>
        <div class="field">
          <strong>עיריה: </strong>
          <span>{{ reportWidgetModal.Municipality.Name }}</span>
        </div>
        <div class="field">
          <strong>כתובת: </strong>
          <span>{{ reportWidgetModal.Address }}</span>
        </div>
        <div class="field">
          <strong>סכום לתשלום: </strong>
          <span>{{ reportWidgetModal.CurrentAmount }} ₪</span>
        </div>
        <div class="field">
          <strong>סיבת הדוח: </strong>
          <span>{{ reportWidgetModal.Reason }}</span>
        </div>
      </div>
      <router-link
        :to="{ name: 'Report', params: { id: reportWidgetModal.Id } }"
      >
        <span class="preview">הצג</span>
      </router-link>
    </div>
  </transition>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "ReportDetailsWidget",
  props: ["ready", "namespace", "fetch"],
  beforeCreate() {
    this.$options.computed = {
      ...mapGetters("report", ["reportWidgetModal"]),
    };
  },
  methods: {
    init() {
      this.$emit("onReady", true);
    },
  },
  watch: {
    ready(newValue) {
      if (newValue) {
        this.init();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.ReportDetailsWidget__Component {
  width: 100%;
  border-radius: 3px;
  padding: 10px;
  border: 1px solid #e4eaf1;
  position: relative;
}

.preview {
  position: absolute;
  left: 10px;
  top: 10px;
  color: #57b7ca;
  cursor: pointer;
  font-weight: 500 !important;

  &:hover {
    text-decoration: underline;
  }
}
</style>
