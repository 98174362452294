<template>
    <transition name="fade">
        <div class="AccidentMyVehicleWidget__Component" v-if="!isInitiating">
            <div v-if="ready" class="files">
                <div class="file"
                    :key="index"
                    v-for="(image, index) in MyVehicle">
                    <img width="100" height="100" :src="image.ImageUrl" alt="">
                    <span>{{getImageType(image.ImageType)}}</span>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import { AccidentImageTypesNames } from '@/types/AccidentImageTypes';

export default {
    name: 'AccidentMyVehicleWidget',
    props: ['ready', 'namespace', 'query', 'widget'],
    beforeCreate() {
        const { namespace } = this.$options.propsData;
        const { mapState } = createNamespacedHelpers(namespace);
        this.$options.computed = {
            ...mapState([this.$options.propsData.widget.field]),
        };
    },
    data() {
        return {
            html: null,
            isInitiating: true,
            isLoading: false,
        };
    },
    methods: {
        async init() {
            this.$emit('onReady');
            this.isInitiating = false;
        },
        getImageType(type) {
            return AccidentImageTypesNames[type];
        },
    },
    watch: {
        ready(newValue) {
            if (newValue) {
                this.init();
            }
        },
    },
};
</script>

<style lang="scss" scoped>
    .AccidentMyVehicleWidget__Component {
        padding: 10px;

        .files {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;

            .file {
                margin: 10px;
                display: flex;
                flex-direction: column-reverse;
                justify-content: center;
                align-items: center;

                img {
                    max-width: 100%;
                }
            }
        }
    }
</style>
