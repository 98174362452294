var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "fade" } }, [
    _vm.ready
      ? _c("div", { staticClass: "DriverContractForReportWidget__Component" }, [
          _vm.contract
            ? _c("div", [
                _c(
                  "div",
                  [
                    _c("b", [_vm._v("מתאריך: ")]),
                    _c("FieldDateHourInline", {
                      attrs: { value: _vm.contract.StartDate }
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  [
                    _c("b", [_vm._v("עד תאריך: ")]),
                    _c("FieldDateHourInline", {
                      attrs: { value: _vm.contract.EndDate }
                    })
                  ],
                  1
                ),
                _c("div", [
                  _vm._v(' עודכן ע"י: '),
                  _c("span", [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.contract.UpdatingUserName
                            ? _vm.contract.UpdatingUserName
                            : "-"
                        ) +
                        " "
                    )
                  ])
                ])
              ])
            : _vm._e(),
          !_vm.contract ? _c("div", [_vm._v("לא קיים חוזה נהג")]) : _vm._e()
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }