var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.ready
    ? _c("div", { staticClass: "DriverWidget__Component" }, [
        _vm.driver.Name && _vm.data
          ? _c("div", [
              _c("div", [_vm._v("שם נהג: " + _vm._s(_vm.driver.Name))]),
              _c("div", [
                _vm._v(
                  "ת.ז: " +
                    _vm._s(_vm.data.IdNumber ? _vm.data.IdNumber : "אין נתונים")
                )
              ]),
              _c("div", [
                _vm._v(
                  " כתובת: " +
                    _vm._s(
                      _vm.data.Street +
                        " " +
                        _vm.data.HouseNumber +
                        "," +
                        _vm.data.City
                    ) +
                    " "
                )
              ]),
              _c("div", [
                _vm._v(
                  " מספר ניתור: " +
                    _vm._s(
                      _vm.data.TrackerDriverId
                        ? _vm.data.TrackerDriverId
                        : "אין נתונים"
                    ) +
                    " "
                )
              ]),
              _c("div", [
                _vm._v(
                  " מספר רשיון: " +
                    _vm._s(
                      _vm.data.LicenseNumber
                        ? _vm.data.LicenseNumber
                        : "אין נתונים"
                    ) +
                    " "
                )
              ]),
              _c("div", [
                _vm._v(
                  "יש טופס הסבה: " +
                    _vm._s(_vm.data.HasTransferForm ? "כן" : "לא")
                )
              ]),
              _c("div", [
                _vm._v(
                  "יש תצלום רישון: " +
                    _vm._s(_vm.data.HasDriversLicenseHtml ? "כן" : "לא")
                )
              ])
            ])
          : _vm._e(),
        _c(
          "div",
          { staticClass: "driver-widget-btns" },
          [
            _vm.isAdmin && _vm.isExactTabs
              ? _c("a", { on: { click: _vm.openModal } }, [_vm._v(" ערוך ")])
              : _vm._e(),
            _c(
              "router-link",
              {
                attrs: { to: { name: "Driver", params: { id: _vm.driver.Id } } }
              },
              [_c("span", {}, [_vm._v("הצג")])]
            )
          ],
          1
        ),
        _vm.ready && !_vm.driver.Name
          ? _c("div", [_vm._v("לא שוייך נהג")])
          : _vm._e()
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }