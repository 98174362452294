<template>
    <transition name="fade">
        <div v-if="results != null && ready">אין נסיעות כבישי אגרה לרכב זה</div>
    </transition>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import TollRoadReportsService from '@/services/TollRoadReportsService';

export default {
    name: 'VehicleReportsWidget',
    props: ['ready', 'namespace', 'query'],
    beforeCreate() {
        const { namespace } = this.$options.propsData;
        const { mapState } = createNamespacedHelpers(namespace);
        this.$options.computed = {
            ...mapState(['Id']),
        };
    },
    data() {
        return {
            results: null,
        };
    },
    methods: {
        init() {
            if (this.query) {
                TollRoadReportsService.search({ ...this.query(this.Id) })
                .then((r) => {
                    this.results = r.data.TotalResults;
                })
                .finally(() => {
                    this.$emit('onReady', { titleCount: this.results });
                });
            }
        },
    },
    watch: {
        ready(newValue) {
            if (newValue) {
                this.init();
            }
        },
    },
};
</script>

<style lang="scss" scoped>
    .VehicleContractsWidget__Component {
        // background-color: #eaf0f6;
        width: 100%;

        .VehicleContractsWidget__Component__Item {
            border-radius: 3px;
            margin: 10px 0;
            padding: 10px;
            border: 1px solid #e4eaf1;
            position: relative;
            display: flex;
            flex-direction: column;
            font-weight: bold;
            background-color: #c1cdff66;

            span {
                font-weight: normal;
            }
            a {
                color: #06c;
                cursor: pointer;
                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
</style>
