<template>
  <div class="ReportPayment__Widget" v-if="ready">
    <label>
      <span>שולם</span>
      <div>{{IsPaid ? 'כן' : 'לא'}}</div>
    </label>
    <!-- <label>
      <span>תשלום מוטל על הנהג</span>
      <div>{{model.IsPaidByDriver?'כן':'לא'}}</div>
    </label> -->
    <label v-if="OverpaidAmount">
      <span>סכום חורג ששולם</span>
      <div>{{OverpaidAmount}}</div>
    </label>
    <label v-if="PartialPaidAmount">
      <span>חלק ששולם</span>
      <div>{{PartialPaidAmount}}</div>
    </label>
    <label v-if="PaymentDate">
      <span>תאריך תשלום</span>
      <div>{{PaymentDate | datetime}}</div>
    </label>
    <label v-if="PaymentReferenceNumber">
      <span>אסמכתה</span>
      <div>{{PaymentReferenceNumber}}</div>
    </label>
  </div>
</template>

<script>
// import { createNamespacedHelpers } from 'vuex';
import { mapState } from 'vuex';

export default {
    name: 'ReportPaymentWidget',
    props: ['ready', 'namespace'],
    computed: {
      ...mapState('report', [
        'IsPaid',
        'OverpaidAmount',
        'PartialPaidAmount',
        'PaymentDate',
        'PaymentReferenceNumber',
        'PaymentUrl',
        'IsPayable',
      ]),
    },
    methods: {
      init() {
        this.model = { ...this.model, ...this.reportPaymentModel };
        this.$emit('onReady');
      },
    },
    watch: {
      ready(newValue) {
        if (newValue) {
          this.init();
        }
      },
    },
};
</script>

<style lang="scss" scoped>
.ReportPayment__Widget {
  label {
    display: flex;
    span {
      font-weight: 600;
      padding-left: 5px;
      &::after {
        content: ':'
      }
    }
  }
  .payable {
    margin-top: 10px;
    color: #5a5a5a;
    border-radius: 3px;
    padding: 2px 10px;
    background-color: #ffd17e;
  }
}
</style>
