<template>
  <div class="ActivityItemTask__Component">
    <div v-if="isOpen" class="Activity__Item__Box__Content__Text__Editor__Wrapper">
        <div v-if="!editBodyMode" class="Activity__Item__Box__Content__Text__Editor"
        :class="{open: isOpen}"
        v-html="model.Body"
        @click="editBody()"></div>

        <div v-if="editBodyMode">
            <NewActivityEditor ref="editorBody" :content="model.Body" />
            <div class="Activity__Item__Box__Content__Text__Editor__Actions">
                <b-button :loading="isLoading" @click="updateBody()" class="Save">שמור</b-button>
                <b-button :disabled="isLoading" @click="editBodyMode=false"
                    class="Cancel">בטל</b-button>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import EntityItemsService from '@/services/EntityItemsService';
import NewActivityEditor from './NewActivityEditor.vue';

export default {
    name: 'ActivityItemComment',
    props: ['item', 'isOpen', 'entityType', 'entityId'],
    components: {
        NewActivityEditor,
    },
    data() {
        return {
            isLoading: false,
            editBodyMode: false,
            model: {

            },
        };
    },
    created() {
        this.model = { ...this.item };
    },
    methods: {
        onChange(isEmpty) {
            this.hasContent = !isEmpty;
            this.update();
        },
        editBody() {
            this.editBodyMode = true;
        },
        complete() {
            this.model.IsComplete = !this.model.IsComplete;
            EntityItemsService.complete(this.entityType, this.entityId,
                this.item.Id, this.model.IsComplete);
        },
        updateBody() {
            const body = this.$refs.editorBody.getHTML();
            this.model.Body = body;
            EntityItemsService.editNote(this.entityType, this.entityId, this.item.Id, this.model)
            .then(() => {
                this.item.Body = body;
                this.editBodyMode = false;
            });
        },
    },
};
</script>

<style scoped lang="scss">
    .ActivityItemTask__Component__Edit {
        padding: 15px 0;
        border-top: 1px solid #d8d8d8;
    }
    .Activity__Item__Box__Content__Text__Editor {
        padding-right: 15px;
        margin: 10px 0;
    }
    .Activity__Item__Box__Content__Text__Editor__Actions {
        margin-bottom: 20px;

        button {
          border: none;
          background-color: transparent;
          padding: 5px 10px;
          cursor: pointer;
          line-height: normal;
          height: unset;
          font-size: 15px;

          &:active {
              transform: scale(.97);
            }

          &.Save {
            background-color: #486480;
            color: white;
            border-radius: 3px;
            transition: transform .1s cubic-bezier(0.075, 0.82, 0.165, 1);
            &:hover {
              background-color: #597794;
            }
          }
          &.Cancel {
            background-color: #d0d0d0;
            color: black;
            border-radius: 3px;
            margin-right: 5px;

            &:hover {
              background-color: #e4e4e4;
            }
          }
        }
    }
    .Activity__Item__Box__Content__Text__Editor {
      direction: rtl;
      padding-right: 14px;
      font-size: 14px;
      position: relative;
      color: black;
      padding: 10px 10px 30px 10px;
      border: 1px solid transparent;
      border-radius: 3px;
      max-height: 55px;
      overflow: hidden;

      &.--Title {
          padding: 0;
          margin: 0;
      }

      &:not(.open)::after {
        background-image: linear-gradient(#04040400 50%, #ffffff);
        height: 55px;
        opacity: 1;
        pointer-events: none;
        position: absolute;
        top: 0px;
        left: 0px;
        right: 0px;
        content: '';
      }
        &.open {
          max-height: unset;
          &:hover {
            background-color: #e5f5f8;
            border: 1px solid #addbff;
        }
      }
    }
</style>
