<template>
  <transition name="fade">
    <div v-if="ready" class="VehicleWidget__Component">
      <div v-if="vehicle.Name && !data">
        <div>{{ vehicle.Name }}</div>
        <div>{{ vehicle.IdNumber }}</div>
        <span v-if="fetch" class="loading">
          <ClipLoader color="#2e3f50" :loading="loading" :size="20" />
        </span>
      </div>
      <div v-if="data" class="VehicleWidget__Component__Extra">
        <label>
          מספר רכב:
          <span>{{ data.PlateNumber }}</span>
        </label>
        <label>
          בעלות:
          <a href=""><span>{{ data.Ownership && data.Ownership.Name }}</span></a>
        </label>
        <label>
          סוג:
          <span>{{ data.VehicleType && data.VehicleType.Name }}</span>
        </label>
        <label>
          יצרן:
          <span>{{ data.Manufacturer && data.Manufacturer.Name }}</span>
        </label>
        <label>
          מודל:
          <span>{{ data.Model }}</span>
        </label>
        <label>
          תאריך יצירה:
          <span>{{ displayDate() }}</span>
        </label>
        <router-link v-if="isAdmin" :to="{ name: 'AdminVehicle', params: { id: data.Id } }">
          <span class="preview">הצג</span>
        </router-link>
        <router-link v-else :to="{ name: 'Vehicle', params: { id: data.Id } }">
          <span class="preview">הצג</span>
        </router-link>
      </div>
      <div v-if="!vehicle.Name">לא שוייך רכב</div>
    </div>
  </transition>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import { ClipLoader } from "@saeris/vue-spinners";
import moment from "moment";

export default {
  name: "VehicleWidget",
  props: {
    ready: Boolean,
    namespace: String,
    fetch: Boolean,
    delegate: {
      type: Function,
      required: true,
    },
  },
  components: {
    ClipLoader,
  },
  computed: {
    owner() {
      return this.data?.Ownership;
    },
  },
  data() {
    return {
      vehicle: {
        Name: null,
        Id: null,
      },
      data: null,
      loading: false,
      isAdmin: false
    };
  },
  beforeCreate() {
    const { namespace } = this.$options.propsData;
    const { mapState } = createNamespacedHelpers(namespace);
    this.$options.computed = {
      ...mapState(["Vehicle"]),
    };
  },
  created() {
    this.isAdminWithoutProfileId();
  },
  methods: {
    displayDate() {
      return this.data.createDate ? moment(this.data.CreateDate).format("DD/MM/YYYY") : '';
    },
    isAdminWithoutProfileId() {
      (!this.$store.state.auth.profileId && this.$store.state.auth?.user?.claims?.isAdmin === "True") ? this.isAdmin = true : this.isAdmin = false;
    },
    init() {
      if (this.Vehicle) {
        this.vehicle = { ...this.Vehicle };
        if (this.fetch) {
          this.loading = true;
          this.delegate(this.vehicle.Id)
            .then((r) => {
              this.data = r.data;
            })
            .finally(() => {
              this.loading = false;
            });
        }
      }
      this.$emit("onReady", true);
    },
  },
  watch: {
    ready(newValue) {
      if (newValue) {
        this.init();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.VehicleWidget__Component {
  // background-color: #eaf0f6;
  width: 100%;
  border-radius: 3px;
  padding: 10px;
  border: 1px solid #e4eaf1;
  background-color: #eef1f3;
  border-radius: 3px;
  position: relative;

  .VehicleWidget__Component__Extra {
    display: flex;
    flex-direction: column;
    font-weight: bold;

    span {
      font-weight: normal;
    }

    a {
      color: #06c;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .loading {
    position: absolute;
    left: 10px;
    top: 0;
    transform: translateY(50%);
  }

  .preview {
    position: absolute;
    left: 10px;
    top: 10px;
    color: #57b7ca;
    cursor: pointer;
    font-weight: 500 !important;

    &:hover {
      text-decoration: underline;
    }
  }
}
</style>
