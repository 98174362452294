<template>
    <transition name="fade">
        <div v-if="ready && data.length" class="VehicleOwnersWidget__Component">
            <div class="VehicleOwnersWidget__Component__Item"
                v-for="item in data" :key="item.VehicleOwnerId">
                <label>
                    חברה:
                    <span>{{item.Name}}</span>
                </label>
                <label>
                    מתאריך:
                    <FieldDateHourInline :value="item.StartDate" />
                </label>
                <label>
                    עד תאריך:
                    <FieldDateHourInline :value="item.EndDate" />
                </label>
                <label>
                    מרכז רווח:
                    <span>{{item.ProfitCenter}}</span>
                </label>
            </div>
        </div>
        <div v-else>לא קיימת היסטורית בעלויות</div>
    </transition>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import FieldDateHourInline from '../Fields/FieldDateHourInline.vue';

export default {
    name: 'VehicleOwnersWidget',
    props: ['ready', 'namespace', 'delegate'],
    data() {
        return {
            data: [],
            loading: false,
        };
    },
    beforeCreate() {
        const { namespace } = this.$options.propsData;
        const { mapState } = createNamespacedHelpers(namespace);
        this.$options.computed = {
            ...mapState(['Id']),
        };
    },
    methods: {
        init() {
            this.delegate(this.Id)
            .then((r) => {
                this.data = r.data;
            })
            .finally(() => {
                this.$emit('onReady', { titleCount: this.data?.length });
            });
        },
    },
    watch: {
        ready(newValue) {
            if (newValue) {
                this.init();
            }
        },
    },
    components: {
        FieldDateHourInline,
    },
};
</script>

<style lang="scss" scoped>
    .VehicleOwnersWidget__Component {
        // background-color: #eaf0f6;
        width: 100%;

        .VehicleOwnersWidget__Component__Item {
            border-radius: 3px;
            margin: 10px 0;
            padding: 10px;
            border: 1px solid #e4eaf1;
            position: relative;
            display: flex;
            flex-direction: column;
            font-weight: bold;

            span {
                font-weight: normal;
            }
            a {
                color: #06c;
                cursor: pointer;
                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
</style>
