<template>
  <div>
    <b-button :loading="loading" @click="fetch">בקש תמונות</b-button>
    <div v-if="loading">
      <label>בקשה זו עשויה להימשך עד מספר דקות...</label>
      <br>
      <label>נא התאזר בסבלנות</label>
    </div>
    <div class="images">
      <div v-for="(item, index) in photos" class="image" :key="index">
        <img @click="presentImage(item)" :src="getImgUrl(item)" alt="" />
      </div>
    </div>
    <div v-if="isPresentImage" class="present-image-container">
      <button @click="isPresentImage = false" class="close-btn">
        <b-icon icon="close" size="is-medium"> </b-icon>
      </button>
      <img class="present-image" :src="currUrl" alt="" />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import ReportService from "@/services/ReportService";

export default {
  name: "ReportPhotosWidget",
  props: ["ready"],
  computed: {
    ...mapState("report", ["Id"]),
  },
  data() {
    return {
      loading: false,
      isPresentImage: false,
      photos: [],
    };
  },
  methods: {
    getImgUrl(item) {
      const base64Preface = "data:image/png;base64,";
      return item.IsBase64 ? base64Preface.concat(item.Url) : item.Url;
    },
    presentImage(item) {
      this.currUrl = this.getImgUrl(item);
      this.isPresentImage = true;
    },
    init() {
      this.$emit("onReady", true);
    },
    isAdmin() {
      return this.$store.state.auth?.user?.claims?.isAdmin === "True";
    },
    fetch() {
      this.loading = true;
      (this.isAdmin()
        ? ReportService.getReportImagesAdmin(this.Id)
        : ReportService.getReportImages(this.Id)
      )
        .then((r) => {
          this.photos = r.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  watch: {
    ready(newValue) {
      if (newValue) {
        this.init();
      }
    },
  },
};
</script>

<style lang="scss">
.image {
  width: 100px;
  height: 100px;
  margin: 5px;

  img {
    max-width: 100%;
    max-height: 100px;
    display: block;
    margin: 0 auto;
    cursor: pointer;
  }
}
.present-image-container {
  width: 100%;
  height: 100%;
  background-color: #000000b0;
  position: fixed;
  top: 0;
  right: 0;
  display: flex;
  justify-content: center;
  z-index: 100;
  .close-btn {
    background-color: transparent;
    color: white;
    border: none;
    margin: 50px;
    cursor: pointer;
    right: inherit;
    position: absolute;
  }
  .present-image {
    padding: 90px;
  }
}
</style>
