<template>
  <div class="ReportDeliveryWidget__Component" v-if="ready">
      <div v-for="(item, index) in DeliveryEvents"
        :key="index"
        class="ReportDeliveryWidget__Component__Status">
        <span>{{item.DeliveryDate | date}}</span>
        <strong class="mr-3"><span>{{item.DeliveryMethod && item.DeliveryMethod.Name}}
          </span></strong>
      </div>
      <div v-if="!DeliveryEvents.length">
        לא צוין
      </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import moment from 'moment';

export default {
    name: 'ReportDeliveryWidget',
    props: ['ready', 'namespace'],
    data() {
        return {
          model: [],
        };
    },
    filters: {
      date(value) {
        const date = moment(value, 'YYYY-MM-DDThh:mm');
        return date.format('DD/MM/YYYY hh:mm');
      },
    },
    beforeCreate() {
        const { namespace } = this.$options.propsData;
        const { mapState } = createNamespacedHelpers(namespace);
        this.$options.computed = {
            ...mapState(['DeliveryEvents']),
        };
    },
    methods: {
      init() {
        this.model = [...this.model, ...this.DeliveryEvents];
        this.$emit('onReady', {
          titleCount: this.model.length,
        });
      },
    },
    watch: {
      ready(newValue) {
          if (newValue) {
              this.init();
          }
      },
    },
};
</script>

<style lang="scss" scoped>
.ReportDeliveryWidget__Component {
  width: 100%;
  .ReportDeliveryWidget__Component__Status {
    border: 1px solid #e4eaf1;
    background-color: #eef1f3;
    border-radius: 3px;
    display: flex;
    border-radius: 3px;
    padding: 2px 10px;

    span {
      margin: 0 2px;
      display: block;
    }
  }
}
</style>
