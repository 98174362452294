var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "fade" } }, [
    _vm.ready
      ? _c("div", { staticClass: "VehicleWidget__Component" }, [
          _vm.vehicle.Name && !_vm.data
            ? _c("div", [
                _c("div", [_vm._v(_vm._s(_vm.vehicle.Name))]),
                _c("div", [_vm._v(_vm._s(_vm.vehicle.IdNumber))]),
                _vm.fetch
                  ? _c(
                      "span",
                      { staticClass: "loading" },
                      [
                        _c("ClipLoader", {
                          attrs: {
                            color: "#2e3f50",
                            loading: _vm.loading,
                            size: 20
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e()
              ])
            : _vm._e(),
          _vm.data
            ? _c(
                "div",
                { staticClass: "VehicleWidget__Component__Extra" },
                [
                  _c("label", [
                    _vm._v(" מספר רכב: "),
                    _c("span", [_vm._v(_vm._s(_vm.data.PlateNumber))])
                  ]),
                  _c("label", [
                    _vm._v(" בעלות: "),
                    _c("a", { attrs: { href: "" } }, [
                      _c("span", [
                        _vm._v(
                          _vm._s(_vm.data.Ownership && _vm.data.Ownership.Name)
                        )
                      ])
                    ])
                  ]),
                  _c("label", [
                    _vm._v(" סוג: "),
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.data.VehicleType && _vm.data.VehicleType.Name
                        )
                      )
                    ])
                  ]),
                  _c("label", [
                    _vm._v(" יצרן: "),
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.data.Manufacturer && _vm.data.Manufacturer.Name
                        )
                      )
                    ])
                  ]),
                  _c("label", [
                    _vm._v(" מודל: "),
                    _c("span", [_vm._v(_vm._s(_vm.data.Model))])
                  ]),
                  _c("label", [
                    _vm._v(" תאריך יצירה: "),
                    _c("span", [_vm._v(_vm._s(_vm.displayDate()))])
                  ]),
                  _vm.isAdmin
                    ? _c(
                        "router-link",
                        {
                          attrs: {
                            to: {
                              name: "AdminVehicle",
                              params: { id: _vm.data.Id }
                            }
                          }
                        },
                        [
                          _c("span", { staticClass: "preview" }, [
                            _vm._v("הצג")
                          ])
                        ]
                      )
                    : _c(
                        "router-link",
                        {
                          attrs: {
                            to: { name: "Vehicle", params: { id: _vm.data.Id } }
                          }
                        },
                        [
                          _c("span", { staticClass: "preview" }, [
                            _vm._v("הצג")
                          ])
                        ]
                      )
                ],
                1
              )
            : _vm._e(),
          !_vm.vehicle.Name ? _c("div", [_vm._v("לא שוייך רכב")]) : _vm._e()
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }