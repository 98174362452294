import TextWidget from "@/components/Widgets/TextWidget.vue";
import DriverWidget from "@/components/Widgets/DriverWidget.vue";
import ReportPaymentWidget from "@/components/Widgets/ReportPaymentWidget.vue";
import VehicleWidget from "@/components/Widgets/VehicleWidget.vue";
import ClientWidget from "@/components/Widgets/ClientWidget.vue";
import VehicleContractForReportWidget from "./VehicleContractForReportWidget.vue";
import ContractWidget from "./ContractWidget.vue";
import DriverContractWidget from "./DriverContractWidget.vue";
import DriverContractForVehicleWidget from "./DriverContractForVehicleWidget.vue";
import DriverContractForReportWidget from "./DriverContractForReportWidget.vue";
import ReportPhotosWidget from "./ReportPhotosWidget.vue";
import ReportStatusesWidget from "./ReportStatusesWidget.vue";
import ReportActivitiesWidget from "./ReportActivitiesWidget.vue";
import ReportAlertsWidget from "./ReportAlertsWidget.vue";
import ReportDeliveryWidget from "./ReportDeliveryWidget.vue";
import VehicleContractsWidget from "./VehicleContractsWidget.vue";
import VehicleOwnersWidget from "./VehicleOwnersWidget.vue";
import VehicleOwnershipContracts from "./VehicleOwnershipContracts.vue";
import ReportsQueryWidget from "./ReportsQueryWidget.vue";
import VehicleDrivesWidget from "./VehicleDrivesWidget.vue";
import ReportDetailsWidget from "./ReportDetailsWidget.vue";
import TransferStatusesDriverWidget from "./TransferStatusesDriverWidget.vue";
import TransferStatusesCompanyWidget from "./TransferStatusesCompanyWidget.vue";
import VehiclesQueryWidget from "./VehiclesQueryWidget.vue";
import TollRoadsQueryWidget from "./TollRoadsQueryWidget.vue";
import CustomerDocumentsWidget from "./CustomerDocumentsWidget.vue";
import VehicleContractDocumentsWidget from "./VehicleContractDocumentsWidget.vue";
import DriverContractDocumentsWidget from "./DriverContractDocumentsWidget.vue";
import DriverDocumentsWidget from "./DriverDocumentsWidget.vue";
import AccidentPdfFileWidget from "./AccidentPdfFileWidget.vue";
import AccidentVehicleWidget from "./AccidentVehicleWidget.vue";
import VehicleCurrentOwnership from "./VehicleCurrentOwnership.vue";
import VehicleCurrentOwnerWidget from "./VehicleCurrentOwnerWidget.vue";
import ReportHistoryWidget from "./ReportHistoryWidget.vue";
import PreviewDocumentWidget from "./PreviewDocumentWidget.vue";
import PreviewDriverDocumentWidget from "./PreviewDriverDocumentWidget.vue";

export default {
  DriverWidget,
  TextWidget,
  ReportPaymentWidget,
  VehicleWidget,
  ClientWidget,
  ContractWidget,
  DriverContractWidget,
  DriverContractForVehicleWidget,
  DriverContractForReportWidget,
  VehicleContractForReportWidget,
  ReportPhotosWidget,
  ReportStatusesWidget,
  ReportActivitiesWidget,
  ReportAlertsWidget,
  ReportDeliveryWidget,
  VehicleContractsWidget,
  VehicleOwnersWidget,
  VehicleOwnershipContracts,
  ReportsQueryWidget,
  VehicleDrivesWidget,
  ReportDetailsWidget,
  TransferStatusesDriverWidget,
  TransferStatusesCompanyWidget,
  VehiclesQueryWidget,
  TollRoadsQueryWidget,
  CustomerDocumentsWidget,
  DriverDocumentsWidget,
  VehicleContractDocumentsWidget,
  DriverContractDocumentsWidget,
  AccidentPdfFileWidget,
  AccidentVehicleWidget,
  VehicleCurrentOwnership,
  VehicleCurrentOwnerWidget,
  ReportHistoryWidget,
  PreviewDocumentWidget,
  PreviewDriverDocumentWidget
};
