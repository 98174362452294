<template>
  <div class="ReportActivitiesWidget__Component" v-if="ready">
    <div v-for="(item, index) in model" :key="index" :class="{ complete: item.IsComplete }"
      class="ReportActivitiesWidget__Component__Activity" @click="goToEntity(item)">
      <b-icon :icon="item.IsComplete ? 'check-circle' : ''" />
      <b-icon :icon="item.ItemType === 1 ? 'message-bulleted' : 'calendar-plus'" />
      <div :style="[item.IsComplete ? {'text-decoration': 'line-through'} : '']" v-html="itemText(item)"></div>
    </div>
    <div v-if="!model.length">לא קיימות פעילויות</div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import EntityItemsService from "@/services/EntityItemsService";
import moment from "moment";

export default {
  name: "ReportActivitiesWidget",
  props: ["ready", "namespace"],
  data() {
    return {
      model: [],
      isAdmin: false
    };
  },
  filters: {
    date(value) {
      const date = moment(value, "YYYY-MM-DDThh:mm");
      return date.format("DD/MM/YYYY hh:mm");
    },
  },
  beforeCreate() {
    const { namespace } = this.$options.propsData;
    const { mapState } = createNamespacedHelpers(namespace);
    this.$options.computed = {
      ...mapState(["Id"]),
    };
  },
  created() {
    this.isAdminWithoutProfileId();
  },
  methods: {
    isAdminWithoutProfileId() {
      (!this.$store.state.auth.profileId && this.$store.state.auth?.user?.claims?.isAdmin === "True") ? this.isAdmin = true : this.isAdmin = false;
    },
    parsedDate(date) {
      if (date) {
        const localLocale = moment(date);
        localLocale.locale("he");
        return `${localLocale.format(
          "MMM DD, YYYY"
        )} בשעה  ${localLocale.format("HH:mm")}`;
      }
      return null;
    },
    itemText(item) {
      // eslint-disable-next-line default-case
      switch (item.ItemType) {
        case 1:
          // eslint-disable-next-line no-case-declarations
          return `
        <div class="activity-details-container">
                <div>הערה</div>
                 <div class="details-header"> ${item.CreatorName
            } , ${this.parsedDate(item.CreateDate)}</div>
          </div>
        </div>
        <div class="content-container">
            <div>
            ${item.Body}
            </div>
        </div>
          `;

        case 2:
          return `
          <div class="activity-details-container">
                <div> ${item.Title}</div>
                  <div class="details-header"> ${item.CreatorName
            } , ${this.parsedDate(item.CreateDate)}</div>
          </div>
          <div class="content-container">
              <div>
                ${item.Body}
              </div>
          </div>
          `;
        default:
          return "משימה/הערה";
      }
    },
    goToEntity(item) {
      if (this.isAdmin) {
        this.$router.replace({
          path: `/admin/reports/${this.Id}`,
          query: { taskId: item.Id },
        });
      } else {
        this.$router.replace({
          path: `/reports/${this.Id}`,
          query: { taskId: item.Id },
        });
      }
    },
    async init() {
      const res = await EntityItemsService.getEntity("Report", this.Id);
      this.ActivityEvents = res.data;
      this.model = [...this.model, ...this.ActivityEvents];
      this.$emit("onReady", {
        titleCount: this.model.length,
      });
    },
  },
  watch: {
    ready(newValue) {
      if (newValue) {
        this.init();
      }
    },
    ActivityEvents(newValue) {
      this.$emit("onReady", {
        titleCount: newValue?.length,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.ReportActivitiesWidget__Component {
  display: flex;
  flex-direction: column;

  .ReportActivitiesWidget__Component__Activity {
    > :first-child {
      margin: 0 0 0 5px;
    }

    display: inline-flex;
    align-items: flex-start;
    border: 1px #b6dcf5 solid;
    border-radius: 3px;
    padding: 7px 10px;
    margin: 3px 0;
    cursor: pointer;

    div {
      display: flex;
      display: block;
      width: 90%;

      ::v-deep .activity-details-container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        .details-header {
          width: 100%;
          font-size: 11px;
          color: grey;
        }

        >* {
          margin-left: 30px;
        }
      }

      ::v-deep .content-container {
        display: flex;
        overflow: hidden;
        height: 21px;
        width: 275px;

        p {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 280px;
        }
      }

      ::v-deep .text-span {
        min-width: fit-content;
        padding: 0 0 0 5px;
      }

    }

    ::v-deep .mdi-check-circle {
      color: #00bda5;
    }
  }

  ::v-deep {
    .icon .check-circle {
      color: #00bda5;
    }
  }
}
</style>
