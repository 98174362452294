var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "fade" } }, [
    _vm.ready && _vm.data.length
      ? _c(
          "div",
          { staticClass: "VehicleOwnersWidget__Component" },
          _vm._l(_vm.data, function(item) {
            return _c(
              "div",
              {
                key: item.VehicleOwnerId,
                staticClass: "VehicleOwnersWidget__Component__Item"
              },
              [
                _c("label", [
                  _vm._v(" חברה: "),
                  _c("span", [_vm._v(_vm._s(item.Name))])
                ]),
                _c(
                  "label",
                  [
                    _vm._v(" מתאריך: "),
                    _c("FieldDateHourInline", {
                      attrs: { value: item.StartDate }
                    })
                  ],
                  1
                ),
                _c(
                  "label",
                  [
                    _vm._v(" עד תאריך: "),
                    _c("FieldDateHourInline", {
                      attrs: { value: item.EndDate }
                    })
                  ],
                  1
                ),
                _c("label", [
                  _vm._v(" מרכז רווח: "),
                  _c("span", [_vm._v(_vm._s(item.ProfitCenter))])
                ])
              ]
            )
          }),
          0
        )
      : _c("div", [_vm._v("לא קיימת היסטורית בעלויות")])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }