var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "fade" } }, [
    !_vm.isInitiating
      ? _c(
          "div",
          { staticClass: "VehicleContractDocumentsWidget__Component" },
          [
            _vm.ready
              ? _c("div", [
                  _vm.show
                    ? _c("button", { on: { click: _vm.showDocument } }, [
                        _vm._v("הצג מסמך")
                      ])
                    : _vm._e(),
                  _vm.show
                    ? _c("button", { on: { click: _vm.deleteDocument } }, [
                        _vm._v("מחק מסמך")
                      ])
                    : _vm._e(),
                  !_vm.hasDocument && !_vm.isLoading
                    ? _c("button", { on: { click: _vm.showDocument } }, [
                        _vm._v("הוסף מסמך")
                      ])
                    : _vm._e()
                ])
              : _vm._e(),
            _c("b-loading", {
              attrs: { "is-full-page": false, "can-cancel": false },
              model: {
                value: _vm.isLoading,
                callback: function($$v) {
                  _vm.isLoading = $$v
                },
                expression: "isLoading"
              }
            })
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }