var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "fade" } }, [
    _vm.ready
      ? _c(
          "div",
          { staticClass: "VehicleContractForReportWidget__Component" },
          [
            _vm.contract
              ? _c(
                  "div",
                  {
                    staticClass:
                      "VehicleContractForReportWidget__Component__Item"
                  },
                  [
                    _c("label", [
                      _vm._v(" שוכר: "),
                      _c(
                        "a",
                        {
                          attrs: { href: "/clients/" + _vm.contract.Profile.Id }
                        },
                        [
                          _c("span", [
                            _vm._v(_vm._s(_vm.contract.Profile.Name))
                          ])
                        ]
                      )
                    ]),
                    _c("label", [
                      _vm._v(" ח.פ: "),
                      _c("span", [
                        _vm._v(_vm._s(_vm.contract.Profile.IdNumber))
                      ])
                    ]),
                    _c(
                      "label",
                      [
                        _vm._v(" מתאריך: "),
                        _c("FieldDateHourInline", {
                          attrs: { value: _vm.contract.StartDate }
                        })
                      ],
                      1
                    ),
                    _c(
                      "label",
                      [
                        _vm._v(" עד תאריך: "),
                        _c("FieldDateHourInline", {
                          attrs: { value: _vm.contract.EndDate }
                        })
                      ],
                      1
                    ),
                    _c("label", [
                      _vm._v(" הצהרת חוכר: "),
                      _c("span", [
                        _vm._v(
                          _vm._s(_vm.contract.HasTransferHtml ? "כן" : "לא")
                        )
                      ])
                    ]),
                    _c("label", [
                      _vm._v(" קיים טופס איסוף השכרה: "),
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.contract.HasRentalPickUpFormHtml ? "כן" : "לא"
                          )
                        )
                      ])
                    ]),
                    _c("label", [
                      _vm._v(" קיים טופס החזרת השכרה: "),
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.contract.HasRentalReturnFormHtml ? "כן" : "לא"
                          )
                        )
                      ])
                    ]),
                    _c("label", [
                      _vm._v(" קיים רישיון נהיגה: "),
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.contract.HasDriversLicenseHtml ? "כן" : "לא"
                          )
                        )
                      ])
                    ]),
                    _c(
                      "label",
                      [
                        _vm._v(" תאריך עדכון: "),
                        _c("FieldDateHourInline", {
                          attrs: { value: _vm.contract.UpdateDate }
                        })
                      ],
                      1
                    ),
                    _c("label", [
                      _vm._v(' עודכן ע"י: '),
                      _c("span", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.contract.UpdatingUserName
                                ? _vm.contract.UpdatingUserName
                                : "-"
                            ) +
                            " "
                        )
                      ])
                    ]),
                    _c(
                      "router-link",
                      {
                        attrs: {
                          to: {
                            name: "VehicleContract",
                            params: { id: _vm.contract.Id }
                          }
                        }
                      },
                      [_c("span", { staticClass: "preview" }, [_vm._v("הצג")])]
                    )
                  ],
                  1
                )
              : _vm._e(),
            !_vm.contract ? _c("div", [_vm._v("לא קיים חוזה רכב")]) : _vm._e()
          ]
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }