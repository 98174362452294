var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "ActivityItemTask__Component" }, [
    _vm.isOpen
      ? _c(
          "div",
          {
            staticClass: "Activity__Item__Box__Content__Text__Editor__Wrapper"
          },
          [
            !_vm.editBodyMode
              ? _c("div", {
                  staticClass: "Activity__Item__Box__Content__Text__Editor",
                  class: { open: _vm.isOpen },
                  domProps: { innerHTML: _vm._s(_vm.model.Body) },
                  on: {
                    click: function($event) {
                      return _vm.editBody()
                    }
                  }
                })
              : _vm._e(),
            _vm.editBodyMode
              ? _c(
                  "div",
                  [
                    _c("NewActivityEditor", {
                      ref: "editorBody",
                      attrs: { content: _vm.model.Body }
                    }),
                    _c(
                      "div",
                      {
                        staticClass:
                          "Activity__Item__Box__Content__Text__Editor__Actions"
                      },
                      [
                        _c(
                          "b-button",
                          {
                            staticClass: "Save",
                            attrs: { loading: _vm.isLoading },
                            on: {
                              click: function($event) {
                                return _vm.updateBody()
                              }
                            }
                          },
                          [_vm._v("שמור")]
                        ),
                        _c(
                          "b-button",
                          {
                            staticClass: "Cancel",
                            attrs: { disabled: _vm.isLoading },
                            on: {
                              click: function($event) {
                                _vm.editBodyMode = false
                              }
                            }
                          },
                          [_vm._v("בטל")]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              : _vm._e()
          ]
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }