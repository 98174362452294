<template>
  <div class="ReportStatusesWidget__Component" v-if="ready">
      <div v-for="(item, index) in StatusEvents"
        :key="index"
        class="ReportStatusesWidget__Component__Status">
        <label>
          <span>{{item.Status && item.Status.Name}} {{item.Date | date}} </span>
        </label>
        <label>
          <p>עודכן ע"י: {{item.UpdatingUserName ? item.UpdatingUserName : '-'}}</p>
        </label>
      </div>
      <div v-if="!StatusEvents.length">
        לא קיים סטטוס
      </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import moment from 'moment';

export default {
    name: 'ReportStatusesWidget',
    props: ['ready', 'namespace'],
    data() {
        return {
          model: [],
        };
    },
    filters: {
      date(value) {
        const date = moment(value, 'YYYY-MM-DDThh:mm');
        return date.format('DD/MM/YYYY hh:mm');
      },
    },
    beforeCreate() {
        const { namespace } = this.$options.propsData;
        const { mapState } = createNamespacedHelpers(namespace);
        this.$options.computed = {
            ...mapState(['StatusEvents']),
        };
    },
    methods: {
      init() {
        this.model = [...this.model, ...this.StatusEvents];
        this.$emit('onReady', {
          titleCount: this.model.length,
        });
      },
    },
    watch: {
      ready(newValue) {
          if (newValue) {
              this.init();
          }
      },
      StatusEvents(newValue) {
        this.$emit('onReady', {
          titleCount: newValue?.length,
        });
      },
    },
};
</script>

<style lang="scss" scoped>
.ReportStatusesWidget__Component {
  .ReportStatusesWidget__Component__Status {
    display: flex;
    background-color: #b6dcf5;
    border-radius: 3px;
    padding: 2px 10px;
    margin: 7px 0;
    flex-direction: column;

    span {
      margin: 0 2px;
      display: block;
    }
  }
}
</style>
