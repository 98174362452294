<template>
    <transition name="fade">
        <div v-if="ready" class="DriverContractForReportWidget__Component">
            <div v-if="contract">
                <div>
                    <b>מתאריך: </b>
                    <FieldDateHourInline :value="contract.StartDate" />
                </div>
                <div>
                    <b>עד תאריך: </b>
                    <FieldDateHourInline :value="contract.EndDate" />
                </div>
                <div>
                        עודכן ע"י:
                    <span> {{ contract.UpdatingUserName ? contract.UpdatingUserName : '-' }} </span>
                </div>
                <!-- <router-link :to="{ name: 'DriverContract', params: { id: contract.Id }}">
                    <span class="preview">הצג</span>
                </router-link> -->
            </div>
            <div v-if="!contract">לא קיים חוזה נהג</div>
        </div>
    </transition>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import ReportService from '@/services/ReportService';
import FieldDateHourInline from '../Fields/FieldDateHourInline.vue';

export default {
    name: 'DriverContractForReportWidget',
    props: ['ready', 'namespace'],
    data() {
        return {
            contract: null,
        };
    },
    beforeCreate() {
        const { namespace } = this.$options.propsData;
        const { mapState } = createNamespacedHelpers(namespace);
        this.$options.computed = {
            ...mapState(['Id']),
        };
    },
    methods: {
        init() {
            if (this.Id) {
                ReportService.getDriverContract(this.Id)
                .then((r) => {
                    this.contract = r.data;
                });
            }
            this.$emit('onReady', true);
        },
    },
    watch: {
        ready(newValue) {
            if (newValue) {
                this.init();
            }
        },
    },
    components: {
        FieldDateHourInline,
    },
};
</script>

<style lang="scss" scoped>
    .DriverContractForReportWidget__Component {
        width: 100%;
        border-radius: 3px;
        padding: 10px;
        border: 1px solid #e4eaf1;
        background-color: #eef1f3;
        border-radius: 3px;
        position: relative;

        .preview {
            position: absolute;
            left: 10px;
            top: 10px;
            color: #57B7CA;
            cursor: pointer;
            font-weight: 500 !important;

            &:hover {
                text-decoration: underline;
            }
        }
    }
</style>
