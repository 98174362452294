var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.ready
    ? _c(
        "div",
        { staticClass: "ReportDeliveryWidget__Component" },
        [
          _vm._l(_vm.DeliveryEvents, function(item, index) {
            return _c(
              "div",
              {
                key: index,
                staticClass: "ReportDeliveryWidget__Component__Status"
              },
              [
                _c("span", [_vm._v(_vm._s(_vm._f("date")(item.DeliveryDate)))]),
                _c("strong", { staticClass: "mr-3" }, [
                  _c("span", [
                    _vm._v(
                      _vm._s(item.DeliveryMethod && item.DeliveryMethod.Name) +
                        " "
                    )
                  ])
                ])
              ]
            )
          }),
          !_vm.DeliveryEvents.length
            ? _c("div", [_vm._v(" לא צוין ")])
            : _vm._e()
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }