<template>
  <div v-if="ready" class="DriverWidget__Component">
    <div v-if="driver.Name && data">
      <div>שם נהג: {{ driver.Name }}</div>
      <div>ת.ז: {{ data.IdNumber ? data.IdNumber : "אין נתונים" }}</div>
      <div>
        כתובת: {{ data.Street + ' ' + data.HouseNumber + ',' + data.City }}
      </div>
      <div>
        מספר ניתור:
        {{ data.TrackerDriverId ? data.TrackerDriverId : "אין נתונים" }}
      </div>
      <div>
        מספר רשיון: {{ data.LicenseNumber ? data.LicenseNumber : "אין נתונים" }}
      </div>
      <div>יש טופס הסבה: {{ data.HasTransferForm ? "כן" : "לא" }}</div>
      <div>יש תצלום רישון: {{ data.HasDriversLicenseHtml ? "כן" : "לא" }}</div>
    </div>
    <div class="driver-widget-btns">
      <a v-if="isAdmin && isExactTabs" @click="openModal">
        ערוך
      </a>
      <router-link :to="{ name: 'Driver', params: { id: driver.Id } }">
        <span class="">הצג</span>
      </router-link>
    </div>
    <div v-if="ready && !driver.Name">לא שוייך נהג</div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import { ModalProgrammatic as Modal } from "buefy";
import DialogUpdateDriver from "../DialogUpdateDriver.vue";
// import DriverService from "@/services/DriverService";

export default {
  name: "DriverWidget",
  props: ["ready", "namespace", "fetch", "delegate"],
  data() {
    return {
      driver: {
        Name: null,
        IdNumber: null,
      },
      data: null,
      isAdmin: false,
      isExactTabs: false
    };
  },
  created() {
      if (this.$store.state.auth?.user?.claims?.isAdmin) this.isAdmin = true;
      if (this.$route.path.includes('admin')) this.isExactTabs = true;
  },
  beforeCreate() {
    const { namespace } = this.$options.propsData;
    const { mapState } = createNamespacedHelpers(namespace);
    this.$options.computed = {
      ...mapState(["Driver"]),
    };
  },
  methods: {
    init() {
      if (this.Driver) {
        this.driver = { ...this.Driver };
        if (this.fetch) {
          this.delegate(this.driver.Id).then((r) => {
            this.data = r.data;
          });
        }
      }
      this.$emit("onReady", true);
    },
    openModal() {
      Modal.open({
        component: DialogUpdateDriver,
        customClass: "entity-modal",
        props: {
          driverId: this.driver.Id,
        },
        canCancel: false,
        trapFocus: true,
      });
    },
  },
  watch: {
    ready(newValue) {
      if (newValue) {
        this.init();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.DriverWidget__Component {
  border: 1px solid #e4eaf1;
  background-color: #eef1f3;
  border-radius: 3px;
  width: 100%;
  padding: 10px;
  position: relative;
  display: flex;
  justify-content: space-between;
}

.driver-widget-btns {
  display: flex;
  gap: 14px;
  & * :hover {
    text-decoration: underline;
  }
}

.preview {
  position: absolute;
  left: 10px;
  top: 10px;
  color: #57b7ca;
  cursor: pointer;
  font-weight: 500 !important;

  &:hover {
    text-decoration: underline;
  }
}
</style>
