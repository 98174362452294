var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.ready
    ? _c("div", { staticClass: "ReportPayment__Widget" }, [
        _c("label", [
          _c("span", [_vm._v("שולם")]),
          _c("div", [_vm._v(_vm._s(_vm.IsPaid ? "כן" : "לא"))])
        ]),
        _vm.OverpaidAmount
          ? _c("label", [
              _c("span", [_vm._v("סכום חורג ששולם")]),
              _c("div", [_vm._v(_vm._s(_vm.OverpaidAmount))])
            ])
          : _vm._e(),
        _vm.PartialPaidAmount
          ? _c("label", [
              _c("span", [_vm._v("חלק ששולם")]),
              _c("div", [_vm._v(_vm._s(_vm.PartialPaidAmount))])
            ])
          : _vm._e(),
        _vm.PaymentDate
          ? _c("label", [
              _c("span", [_vm._v("תאריך תשלום")]),
              _c("div", [_vm._v(_vm._s(_vm._f("datetime")(_vm.PaymentDate)))])
            ])
          : _vm._e(),
        _vm.PaymentReferenceNumber
          ? _c("label", [
              _c("span", [_vm._v("אסמכתה")]),
              _c("div", [_vm._v(_vm._s(_vm.PaymentReferenceNumber))])
            ])
          : _vm._e()
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }