<template>
    <transition name="fade">
        <div class="AccidentPdfFileWidget__Component" v-if="!isInitiating">
            <div v-if="ready">
                <a v-if="hasDocument" :href="PdfUrl" target="_blank">הצג מסמך</a>
            </div>
        </div>
    </transition>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

export default {
    name: 'AccidentPdfFileWidget',
    props: ['ready', 'namespace', 'query', 'widget'],
    beforeCreate() {
        const { namespace } = this.$options.propsData;
        const { mapState } = createNamespacedHelpers(namespace);
        this.$options.computed = {
            ...mapState(['PdfUrl']),
            hasDocument() {
                return this.PdfUrl;
            },
        };
    },
    data() {
        return {
            html: null,
            isInitiating: true,
            isLoading: false,
        };
    },
    methods: {
        async init() {
            this.$emit('onReady');
            this.isInitiating = false;
        },
    },
    watch: {
        ready(newValue) {
            if (newValue) {
                this.init();
            }
        },
    },
};
</script>

<style lang="scss" scoped>
    .AccidentPdfFileWidget__Component {
        padding: 10px;
    }
    a {
        display: block;
        text-align: center;
    }
</style>
